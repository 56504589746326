import { Component } from '@angular/core';
import { CaptureHistoryItemTypeBaseComponent } from '../capture-history-item-type-base/capture-history-item-type-base.component';

@Component({
  selector: 'app-capture-history-item-type-reprocessing-started',
  templateUrl: '../capture-history-item-type-base/capture-history-item-type-base.component.html',
})
export class CaptureHistoryItemTypeReprocessingStartedComponent extends CaptureHistoryItemTypeBaseComponent {
  get title() {
    return 'Reprocessing Started';
  }

  get description() {
    return [
      'Capture reprocessing initiated via ',
      this.captureHistoryItem.relatedRecord.type,
      ' ',
      this.captureHistoryItem.data.action,
      ', ID: ',
      this.captureHistoryItem.relatedRecord.id,
      '.'
    ].join('')
  }
}
