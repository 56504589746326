import { Component, OnDestroy } from '@angular/core';
import { forEach } from 'lodash-es';
import { SearchField } from '../../../core/models/search-field.model';
import { CaptureSearchResult } from '../../../core/models/capture-lists/capture-search-result.model';
import { UserPresenceService } from 'app/core/services/user-presence.service';

@Component({
  selector: 'app-client-portal-search',
  templateUrl: './client-portal-search.component.html',
})
export class ClientPortalSearchComponent implements OnDestroy {
  captures: CaptureSearchResult[] = [];

  searchFields = [
    new SearchField('claimIdentifierSubstring', 'Claim ID'),
    new SearchField('patientFirstName', 'Patient First Name'),
    new SearchField('patientLastName', 'Patient Last Name'),
  ];

  constructor(
    private userPresenceService: UserPresenceService
  ) {}

  ngOnDestroy(): void {
    this.userPresenceService.unsubscribeAll();
  }

  onSearchComplete(captures: CaptureSearchResult[]) {
    this.captures = captures;
    this.subscribeToUserPresence();
  }

  private subscribeToUserPresence() {
    this.userPresenceService.unsubscribeAll();

    forEach(this.captures, (capture: CaptureSearchResult) => {
      this.userPresenceService.subscribe("capture", capture.id)
    });
  }
}
