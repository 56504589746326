import { LocalStorageService } from 'app/core/services/local-storage.service';
import { SessionStorageService } from 'app/core/services/session-storage.service';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FeaturesService } from './services/features.service';
import { GenericCountService } from './services/generic-count.service';
import { ReportsService } from './services/reports.service';
import { DownloadService } from './services/download.service';
import { CaptureService } from './services/capture.service';
import { PatientService } from './services/patient.service';
import { PatientAttachmentsService } from './services/patient-attachments.service';
import { UploadService } from './services/upload.service';
import { MeService } from './services/me.service';
import { CaptureValidationService } from './services/capture-validation.service';
import { PatientMatchVerificationsService } from './services/patient-match-verifications.service';
import { DrugMatchVerificationsService } from './services/drug-match-verifications.service';
import { SpecialistEncounterVerificationService } from './services/specialist-encounter-verification.service';
import { InboundFaxesService } from './services/inbound-faxes.service';
import { ClientsService } from './services/clients.service';
import { ClientSftpCredentialService } from './services/client-sftp-credentials.service';
import { SamlProvidersService } from './services/saml-providers.service';
import { ClientConfigurationOptionsService } from './services/client-configuration-options.service';
import { ClientPrescriberTasksService } from './services/client-prescriber-tasks.service';
import { ClientFilesService } from './services/client-files.service';
import { PartnerFilesService } from './services/partner-files.service';
import { SqsQueueSummaryService } from './services/sqs-queue-summary.service';
import { UserSettingsService } from './services/user-settings.service';
import { QueueProcessingService } from './services/queue-processing.service';
import { FileTargetsService } from './services/file-targets.service';
import { FileRotationsService } from './services/file-rotations.service';
import { FileChecksService } from './services/file-checks.service';
import { FilePreviewService } from './services/file-preview.service';
import { UsersService } from './services/users.service';
import { MyStatsService } from './services/my-stats.service';
import { NavigationService } from './services/navigation.service';
import { SourcesService } from './services/sources.service';
import { SentryService } from './services/sentry.service';
import { ApplicationService } from './services/application.service';
import { BrowserService } from './services/browser.service';
import { HelpScoutService } from './services/help-scout.service';
import { PrescriptionsService } from './services/prescriptions.service';
import { PrescriptionEhrMatchVerificationsService } from './services/prescription-ehr-match-verifications.service';
import { CaptureSnapshotsService } from './services/capture-snapshots.service';
import { ReferralMatchVerificationsService } from './services/referral-match-verifications.service';
import { ClientConsultNoteTasksService } from './services/client-consult-note-tasks.service';
import { ProviderService } from './services/provider.service';
import { ProviderOfficeService } from './services/provider-office.service';
import { ClientConsultNoteVerificationsService } from './services/client-consult-note-verifications.service';
import { ReferralPrescriberSpecialtyMappingService } from './services/referral-prescriber-specialty-mapping.service';
import { OutboundFaxImportsService } from './services/outbound-fax-imports.service';
import { AuthenticationService } from './services/authentication.service';
import { CaptureInvoiceLineItemImportsService } from './services/capture-invoice-line-item-imports.service';
import { PatientInteractionLocationImportsService } from './services/patient-interaction-location-imports.service';
import { ClaimImportsService } from './services/claim-imports.service';
import { PendingHttpRequestsService } from './services/pending-http-requests.service';
import { CaptureSearchService } from './services/capture-search.service';
import { CaptureQueueService } from './services/capture-queue.service';
import { PrescriberMatchVerificationsService } from './services/prescriber-match-verifications.service';
import { ClientReferralTasksService } from './services/client-referral-tasks.service';
import { ClientReferralVerificationsService } from './services/client-referral-verifications.service';
import { EhrReferralVerificationsService } from './services/ehr-referral-verifications.service';
import { EhrConsultNoteReferencesService } from './services/ehr-consult-note-references.service';
import { UserTimingService } from './services/user-timing.service';
import { WebsocketService } from './services/websocket.service';
import { PatientPrescriberService } from './services/patient-prescriber.service';
import { PatientPrescriberOfficesService } from './services/patient-prescriber-offices.service';
import { InboundCallsService } from './services/inbound-calls.service';
import { OutboundFaxesService } from './services/outbound-faxes.service';
import { PrescriberIncorrectInformationRequestsService } from './services/prescriber-incorrect-information-requests.service';
import { NameMappingsService } from './services/name-mappings.service';
import { ClientChoresService } from './services/client-chores.service';
import { CuratedSpecialtiesService } from './services/curated-specialties.service';
import { ViewAsClientService } from './services/view-as-client.service';
import { ConsultNoteQueueService } from './services/consult-note-queue.service';
import { UserAssignedClientsService } from './services/user-assigned-clients.service';
import { ThirdPartyAdministersService } from './services/third-party-administers.service';
import { ProviderCallLogsService } from './services/provider-call-logs.service';
import { OfficeService } from './services/office.service';
import { AuditsService } from './services/audits.service';
import { PdfFileService } from './services/pdf-file.service';
import { LlmFeedbackService } from './services/llm-feedback.service';
import { PatientInteractionLocationsService } from './services/patient-interaction-locations.service';
import { LlmPlaygroundProjectsService } from './services/llm-playground-projects.service';
import { LlmPlaygroundBatchesService } from './services/llm-playground-batches.service';
import { LlmPlaygroundCollaboratorPermissionsService } from './services/llm-playground-collaborator-permissions.service';
import { NotesService } from './services/notes.service';
import { ProviderSpecialtyService } from './services/provider-specialty.service';
import { VersionService } from './services/version.service';
import { PatientCapturesService } from './services/patient-captures.service';
import { ProviderCapturesService } from './services/provider-captures.service';
import { InternalConsultantCapturesService } from './services/internal-consultant-captures.service';
import { WindowActivityService } from './services/window-activity.service';
import { UnsubscribedEmailsService } from './services/unsubscribed_emails.service';

@NgModule({
  imports: [CommonModule, FormsModule],
  providers: [
    AuthenticationService,
    LocalStorageService,
    FeaturesService,
    GenericCountService,
    PatientAttachmentsService,
    DownloadService,
    CaptureService,
    PatientService,
    UploadService,
    MeService,
    CaptureValidationService,
    ReportsService,
    PatientMatchVerificationsService,
    DrugMatchVerificationsService,
    SpecialistEncounterVerificationService,
    InboundFaxesService,
    ClientsService,
    ClientSftpCredentialService,
    SamlProvidersService,
    ClientConfigurationOptionsService,
    SqsQueueSummaryService,
    ClientPrescriberTasksService,
    ClientFilesService,
    PartnerFilesService,
    UserSettingsService,
    QueueProcessingService,
    FileTargetsService,
    FileRotationsService,
    FileChecksService,
    FilePreviewService,
    UsersService,
    MyStatsService,
    NavigationService,
    SourcesService,
    SentryService,
    ApplicationService,
    BrowserService,
    HelpScoutService,
    SessionStorageService,
    PrescriptionsService,
    PrescriptionEhrMatchVerificationsService,
    CaptureSnapshotsService,
    ReferralMatchVerificationsService,
    ClientConsultNoteTasksService,
    ProviderService,
    ProviderOfficeService,
    ClientConsultNoteVerificationsService,
    ReferralPrescriberSpecialtyMappingService,
    OutboundFaxImportsService,
    CaptureInvoiceLineItemImportsService,
    PatientInteractionLocationImportsService,
    ClaimImportsService,
    PendingHttpRequestsService,
    CaptureSearchService,
    CaptureQueueService,
    PrescriberMatchVerificationsService,
    ClientReferralTasksService,
    ClientReferralVerificationsService,
    EhrReferralVerificationsService,
    EhrConsultNoteReferencesService,
    UserTimingService,
    WebsocketService,
    PatientPrescriberService,
    PatientPrescriberOfficesService,
    InboundCallsService,
    OutboundFaxesService,
    PrescriberIncorrectInformationRequestsService,
    NameMappingsService,
    ClientChoresService,
    ProviderCallLogsService,
    CuratedSpecialtiesService,
    ViewAsClientService,
    ConsultNoteQueueService,
    UserAssignedClientsService,
    ThirdPartyAdministersService,
    OfficeService,
    AuditsService,
    PdfFileService,
    PatientInteractionLocationsService,
    DatePipe,
    LlmFeedbackService,
    LlmPlaygroundProjectsService,
    LlmPlaygroundBatchesService,
    LlmPlaygroundCollaboratorPermissionsService,
    NotesService,
    ProviderSpecialtyService,
    VersionService,
    PatientCapturesService,
    ProviderCapturesService,
    InternalConsultantCapturesService,
    WindowActivityService,
    UnsubscribedEmailsService,
  ],
  declarations: [],
})
export class CoreModule { }
