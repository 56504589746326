import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ReferralPrescriberSpecialtyMappingImport } from '../models/referral-prescriber-specialty-mapping-import.model';
import { PagedResults } from '../models/paged-results/paged-results.model';
import { LimitOffsetPagingMeta } from '../models/paged-results/limit-offset-paging.model';

export class PagedReferralPrescriberSpecialtyMappingImports implements PagedResults {
  referralPrescriberSpecialtyMappingImports: ReferralPrescriberSpecialtyMappingImport[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.referralPrescriberSpecialtyMappingImports;
  }
}

@Injectable()
export class ReferralPrescriberSpecialtyMappingService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  create(signedId): Observable<ReferralPrescriberSpecialtyMappingImport> {
    return this.http.post<ReferralPrescriberSpecialtyMappingImport>(
      `${environment.captureApi.url}/referral_prescriber_specialty_mapping_imports`,
      {
        referralPrescriberSpecialtyMappingImport: { signedId },
      }
    );
  }

  getList(
    paging: { page: number; pageSize: number } = null,
  ): Observable<PagedReferralPrescriberSpecialtyMappingImports> {
    const params = new URLSearchParams();

    if (paging) {
      params.append('page', paging.page.toString());
      params.append('per_page', paging.pageSize.toString());
    }

    return this.http.get<PagedReferralPrescriberSpecialtyMappingImports>(
      `${environment.captureApi.url}/referral_prescriber_specialty_mapping_imports?${params.toString()}`
    );
  }
}
