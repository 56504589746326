import { OnInit, OnDestroy, Directive } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { IccImport } from '../../../core/models/icc/icc-import.model';
import { DownloadService } from '../../../core/services/download.service';
import { ClientsService } from '../../../core/services/clients.service';
import { IccImportRenameModalComponent } from '../../components/icc-import-rename-modal/icc-import-rename-modal.component';
import { IccImportFilters } from '../../../core/models/icc/icc-import-settings.model';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';

@Directive()
export abstract class IccImportHistoryComponent implements OnInit, OnDestroy {
  importsAssociatedToClients = true;

  loading = false;
  imports: IccImport[] = [];
  nextCheckTimeout = null;
  clientFilterOptions = [];
  filters: IccImportFilters = new IccImportFilters();
  paging: LimitOffsetPaging;

  importRenameModal: NgbModalRef = null;

  pageTitle = 'Imports';

  showThirdPartyAdminister = false;

  abstract getImports(paging: any, filters: IccImportFilters): Observable<any>;
  abstract getImport(importId: number): Observable<IccImport>;
  abstract cancelImport(importId: number): Observable<IccImport>;
  abstract updateImport(importId: number, name: string): Observable<IccImport>;
  abstract prepareImportResults(data: any): IccImport[];

  constructor(
    protected downloadService: DownloadService,
    protected modalService: NgbModal,
    protected clientsService: ClientsService
  ) {}

  ngOnInit() {
    this.paging = LimitOffsetPaging.empty;
    this.paging.currentPage = 1;
    this.loadImports(this.paging.currentPage);
    this.initClientFilterOptions();
  }

  ngOnDestroy() {
    clearTimeout(this.nextCheckTimeout);
  }

  onPageSizeChange(size: number) {
    this.paging.pageSize = size;
    this.loadImports(this.paging.currentPage);
  }

  onPageChange(page: number) {
    this.loadImports(page);
  }

  onCancelClick($event, iccImport: IccImport) {
    $event.preventDefault();
    this.cancelImport(iccImport.id).subscribe(invoiceImport => {
      const index = this.imports.findIndex(imp => imp.id === invoiceImport.id);

      if (index > -1) {
        this.imports[index] = invoiceImport;
        this.imports = [...this.imports];
      }
    });
  }

  onDownloadOriginalClick($event, iccImport: IccImport) {
    $event.preventDefault();
    this.getImport(iccImport.id).subscribe(invoiceImport => {
      this.downloadService.getDownloadUrl(invoiceImport.fileExpirableDownloadPath).subscribe(expirableDownload => {
        this.downloadService.download(expirableDownload.url);
      });
    });
  }

  onDownloadSkippedClick($event, iccImport: IccImport) {
    $event.preventDefault();
    this.getImport(iccImport.id).subscribe(invoiceImport => {
      const downloadUrl = invoiceImport.skippedFileExpirableDownloadPath;
      this.downloadService.getDownloadUrl(downloadUrl).subscribe(expirableDownload => {
        this.downloadService.download(expirableDownload.url);
      });
    });
  }

  onImportNameEditClick($event, iccImport: IccImport) {
    $event.preventDefault();

    this.importRenameModal = this.modalService.open(IccImportRenameModalComponent, {
      windowClass: 'import-rename-modal',
    });

    this.importRenameModal.componentInstance.iccImport = iccImport;
    this.importRenameModal.componentInstance.updateImport = this.updateImport.bind(this);
  }

  private loadImports(page, loading = true) {
    this.loading = loading;
    const paging = { page, pageSize: this.paging.pageSize };

    this.getImports(paging, this.filters).subscribe(result => {
      this.imports = this.prepareImportResults(result);
      this.paging = result.meta.paging;
      this.loading = false;

      const anyActive: boolean = this.imports.some(invoiceImport => invoiceImport.active);

      if (anyActive) {
        this.nextCheckTimeout = setTimeout(() => this.loadImports(this.paging.currentPage, false), 500);
      }
    });
  }

  private initClientFilterOptions() {
    this.clientsService.getList(null, { applicableToIccClaims: true }).subscribe(result => {
      this.clientFilterOptions = result.clients;
    });
  }

  onFilterChange() {
    this.loadImports(true);
  }
}
