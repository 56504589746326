import { Component, OnDestroy, OnInit } from '@angular/core';
import { OutboundFaxImport } from 'app/core/models/outbound-fax-import.model';
import { OutboundFaxImportsService } from 'app/core/services/outbound-fax-imports.service';
import { DownloadService } from 'app/core/services/download.service';
import { JobStatus } from 'app/core/enums/job-status.enum';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';

@Component({
  selector: 'app-capture-admin-import-outbound-faxes-history',
  templateUrl: './capture-admin-import-outbound-faxes-history.component.html',
  styleUrls: ['./capture-admin-import-outbound-faxes-history.component.scss'],
})
export class CaptureAdminImportOutboundFaxesHistoryComponent implements OnInit, OnDestroy {
  loading = false;
  outboundFaxImports: OutboundFaxImport[] = [];
  nextCheckTimeout = null;
  paging: LimitOffsetPaging;

  constructor(
    private outboundFaxImportsService: OutboundFaxImportsService,
    private downloadService: DownloadService
  ) {}

  ngOnInit() {
    this.paging = LimitOffsetPaging.empty;
    this.paging.currentPage = 1;
    this.loadImports(this.paging.currentPage);
  }

  ngOnDestroy() {
    clearTimeout(this.nextCheckTimeout);
  }

  onPageSizeChange(size: number) {
    this.paging.pageSize = size;
    this.loadImports(this.paging.currentPage);
  }

  onPageChange(page: number) {
    this.loadImports(page);
  }

  onCancelClick($event: MouseEvent, outboundFaxImport: OutboundFaxImport) {
    $event.preventDefault();
    this.outboundFaxImportsService.cancel(outboundFaxImport.id).subscribe(faxImport => {
      const index = this.outboundFaxImports.findIndex(imp => imp.id === faxImport.id);

      if (index > -1) {
        this.outboundFaxImports[index] = faxImport;
        this.outboundFaxImports = [...this.outboundFaxImports];
      }
    });
  }

  onDownloadOriginalClick($event: MouseEvent, outboundFaxImport: OutboundFaxImport) {
    $event.preventDefault();
    this.outboundFaxImportsService.get(outboundFaxImport.id).subscribe(faxImport => {
      this.downloadService.getDownloadUrl(faxImport.fileExpirableDownloadPath).subscribe(expirableDownload => {
        this.downloadService.download(expirableDownload.url);
      });
    });
  }

  onDownloadSkippedClick($event: MouseEvent, outboundFaxImport: OutboundFaxImport) {
    $event.preventDefault();
    this.outboundFaxImportsService.generateSkippedFile(outboundFaxImport.id).subscribe(({ skippedFile }) => {
      if (skippedFile.url) {
        this.downloadService.download(skippedFile.url);
      } else {
        alert('Failed to generate file.');
      }
    });
  }

  onDownloadOutcomesClick($event: MouseEvent, outboundFaxImport: OutboundFaxImport) {
    $event.preventDefault();
    this.outboundFaxImportsService.generateOutcomeFile(outboundFaxImport.id).subscribe(({ outcomeFile }) => {
      if (outcomeFile.url) {
        this.downloadService.download(outcomeFile.url);
      } else {
        alert('Failed to generate file.');
      }
    });
  }

  skippedAndOutcomeFilesAvailable(outboundFaxImport: OutboundFaxImport) {
    return outboundFaxImport.status !== JobStatus.initialized;
  }

  trackImportItemsBy(_index: number, item: OutboundFaxImport) {
    return `${item.id}-${item.status}-${item.totalRows}-${item.queued}-${item.skipped}-${item.scheduledWithRetries}-${item.scheduledWithoutRetries
      }`;
  }

  private loadImports(page, loading = true) {
    this.loading = loading;
    const paging = { page, pageSize: this.paging.pageSize };
    this.outboundFaxImportsService.getList(paging).subscribe(result => {
      this.outboundFaxImports = result.outboundFaxImports;
      this.paging = result.meta.paging;
      this.loading = false;

      const anyActive: boolean = this.outboundFaxImports.some(faxImport => faxImport.active);

      if (anyActive) {
        this.nextCheckTimeout = setTimeout(() => this.loadImports(this.paging.currentPage, false), 1250);
      }
    });
  }
}
