import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UnsubscribedEmail } from '../models/unsubscribed_email.model';

@Injectable()
export class UnsubscribedEmailsService {
  private baseUrl = `${environment.captureApi.url}/unsubscribed_emails`;

  constructor( private http: HttpClient) {}

  getStatus(signature: string): Observable<UnsubscribedEmail> {
    return this.http.get<UnsubscribedEmail>(`${this.baseUrl}/status?signature=${signature}`);
  }

  unsubscribe(signature: string): Observable<UnsubscribedEmail> {
    return this.http.post<UnsubscribedEmail>(`${this.baseUrl}`, { signature });
  }

  resubscribe(signature: string): Observable<UnsubscribedEmail> {
    return this.http.delete<UnsubscribedEmail>(`${this.baseUrl}/?signature=${signature}`);
  }
}
