export class ImportField {
  constructor(public fieldName: string, public description: string) {}
}

const TPAImportFields = {
  "captureRx": [
    new ImportField("Patient First Name", "(required)"),
    new ImportField("Patient Last Name", "(required)"),
    new ImportField("Patient Date of Birth", "(required, date, format MM/DD/YYYY)"),
    new ImportField("NDC#", "(required, 11 digit)"),
    new ImportField("Prescriber ID", "(required, npi, 10 digit)"),
    new ImportField("Date of Service", "(required, date, format MM/DD/YYYY)"),
    new ImportField("Date Prescription Written", "(required, date, format MM/DD/YYYY)"),
    new ImportField("Drug Description", ""),
    new ImportField("Pharmacy NPI#", ""),
    new ImportField("Prescription #", ""),
    new ImportField("Fill Number", ""),
    new ImportField("Net Savings", "")
  ],
  "verity": [
    new ImportField("Pharmacy", "(required)"),
    new ImportField("NDC", "(required, 11 digit)"),
    new ImportField("OPAID", "(required)"),
    new ImportField("Provider NPI", "(required, NPI, 10 digit)"),
    new ImportField("Rx Number", "(required)"),
    new ImportField("Written", "(required, date, format MM/DD/YYYY)"),
    new ImportField("Filled", "(required, date, format MM/DD/YYYY)"),
    new ImportField("Fill #", "(required)"),
    new ImportField("Qty", "(required)"),
    new ImportField("Last", "(required)"),
    new ImportField("First", "(required)"),
    new ImportField("DOB", "(required, date, format MM/DD/YYYY)"),
    new ImportField("Net Savings", "")
  ],
  "devon": [
    new ImportField("Patient Last Name", "(required)"),
    new ImportField("Patient First Name", "(required)"),
    new ImportField("Patient DOB", "(required, date, format MM/DD/YYYY)"),
    new ImportField("Drug NDC", "(required, 11 digit)"),
    new ImportField("Prescriber NPI #", "(required, npi, 10 digit)"),
    new ImportField("Fill Date", "(required, date, format MM/DD/YYYY)"),
    new ImportField("Written Date", "(required, date, format MM/DD/YYYY)"),
    new ImportField("Drug Name", ""),
    new ImportField("Pharmacy NPI #", "(required)"),
    new ImportField("Rx #", "(required)"),
    new ImportField("Refill Number", "(required)"),
    new ImportField("Qty Filled", "(required)"),
    new ImportField("Net Profit", "")
  ]
};

const TPAs = Object.keys(TPAImportFields)
export { TPAImportFields, TPAs };
