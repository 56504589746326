import { Component, OnDestroy, OnInit } from '@angular/core';
import { DownloadService } from '../../../core/services/download.service';
import { ClaimImport } from '../../../core/models/claim-import.model';
import { ClaimImportFilters } from '../../../core/models/user-settings/claim-import-settings.model';
import { ClaimImportsService } from '../../../core/services/claim-imports.service';
import { ClientsService } from '../../../core/services/clients.service';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';

@Component({
  selector: 'app-capture-admin-import-claims-history',
  templateUrl: './capture-admin-import-claims-history.component.html',
  styleUrls: ['./capture-admin-import-claims-history.component.scss'],
})
export class CaptureAdminImportClaimsHistoryComponent implements OnInit, OnDestroy {
  loading = false;
  claimImports: ClaimImport[] = [];
  clientFilterOptions = [];
  filters: ClaimImportFilters = new ClaimImportFilters();
  nextCheckTimeout = null;
  paging: LimitOffsetPaging;

  constructor(
    private downloadService: DownloadService,
    private clientsService: ClientsService,
    private claimImportsService: ClaimImportsService
  ) { }

  ngOnInit() {
    this.paging = LimitOffsetPaging.empty;
    this.paging.currentPage = 1;
    this.loadImports(this.paging.currentPage);
    this.initClientFilterOptions();
  }

  ngOnDestroy() {
  }

  onCancelClick($event, claimImport: ClaimImport) {
    $event.preventDefault();
    this.claimImportsService.cancel(claimImport.id).subscribe(data => {
      const index = this.claimImports.findIndex(imp => imp.id === data.id);

      if (index > -1) {
        this.claimImports[index] = data;
        this.claimImports = [...this.claimImports];
      }
    });
  }

  onDownloadSkippedClick($event, claimImport: ClaimImport) {
    $event.preventDefault();
    this.claimImportsService.get(claimImport.id).subscribe(ci => {
      this.downloadService.getDownloadUrl(ci.skippedFileExpirableDownloadPath).subscribe(expirableDownload => {
        this.downloadService.download(expirableDownload.url);
      });
    });
  }

  onPageSizeChange(size: number) {
    this.paging.pageSize = size;
    this.loadImports(this.paging.currentPage);
  }

  onPageChange(page: number) {
    this.loadImports(page);
  }

  private loadImports(page, loading = true) {
    this.loading = loading;
    const paging = { page, pageSize: this.paging.pageSize };
    this.claimImportsService.getList(paging, this.filters).subscribe(result => {
      this.claimImports = result.claimImports;
      this.paging = result.meta.paging;
      this.loading = false;

      const anyActive: boolean = this.claimImports.some(claimImport => claimImport.active);

      if (anyActive) {
        this.nextCheckTimeout = setTimeout(() => this.loadImports(this.paging.currentPage, false), 500);
      }
    });
  }

  private initClientFilterOptions() {
    this.clientsService.getList(null, { applicableToReferralClaims: true }).subscribe(result => {
      this.clientFilterOptions = result.clients;
    });
  }

  onFilterChange() {
    this.loadImports(this.paging.currentPage);
  }
}
