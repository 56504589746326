export class CaptureSearchParamSet {
  public claimIdentifier = '';
  public claimIdentifierSubstring = '';
  public rxReferenceNumber = '';
  public prescriberNpi = '';
  public patientFirstName = '';
  public patientLastName = '';
  public prescriberFirstName = '';
  public prescriberLastName = '';
  public patientPrescriberUuid = '';
  public captureId = '';
}
