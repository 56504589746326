import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  ActivationStart,
  Event,
  NavigationEnd,
  NavigationStart,
  Params,
  Router,
} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AuthPaths } from '../../auth/enums/auth-paths';
import { PublicPaths } from '../enums/public-paths.enum';
import { SiteSection } from '../enums/site-section-enum';
import { Subject } from 'rxjs';

export class PageChangeEvent {
  constructor(public url: string, public navigatedBack: boolean) { }
}

export const isNavigatingToPublicOrAuthPath = (urlPath: string): boolean => {
  const pathsToCheck = [...Object.values(PublicPaths), ...Object.values(AuthPaths)]

  return pathsToCheck.some(path => pathMatchesRouteDefinition(urlPath, path));
};

export const isNavigatingToPublicPath = (urlPath: string): boolean => {
  return Object.values(PublicPaths).some(path => pathMatchesRouteDefinition(urlPath, path));
};

const pathMatchesRouteDefinition = (urlPath: string, routeDefinition: string) => {
    const routeSegments = routeDefinition.split('/').filter(Boolean);
    const pathSegments = urlPath.split('/').filter(Boolean);

    let lastSegment = pathSegments[pathSegments.length - 1];

    // Truncate the last segment before the '?'. Don't care about query string.
    if (lastSegment?.includes('?')) {
      lastSegment = lastSegment.split('?')[0];
      pathSegments[pathSegments.length - 1] = lastSegment;
    }

    if (pathSegments.length !== routeSegments.length) {
        return false;
    }

    for (let i = 0; i < routeSegments.length; i++) {
        const pathSegment = pathSegments[i];
        const routeSegment = routeSegments[i];

        if (routeSegment.startsWith(':')) {
          // Dynamic segment matches anything
          continue;
        }

        if (pathSegment !== routeSegment) {
            return false;
        }
    }

    return true;
}

@Injectable()
export class NavigationService {
  private _previousUrl: string;
  private _currentUrl: string;
  private _navTrigger;
  private _currentId: string;

  private pageChangedSource = new Subject<PageChangeEvent>();
  pageChanged = this.pageChangedSource.asObservable();

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title) {
    this._currentUrl = this.router.url;

    router.events.subscribe((navEvent: Event) => {
      if (navEvent instanceof NavigationStart) {
        this._navTrigger = navEvent.navigationTrigger;
      }

      if (navEvent instanceof ActivationStart) {
        this._currentId = navEvent.snapshot.paramMap.get('id');
      }

      if (navEvent instanceof NavigationEnd) {
        this.titleService.setTitle('R1 340B'); // default page title
        window.scrollTo(0, 0);
        this._previousUrl = this.currentUrl;
        this._currentUrl = navEvent.url;
        this.pageChangedSource.next(new PageChangeEvent(this._currentUrl, this._navTrigger === 'popstate'));
      }
    });
  }

  public showFatFooter() {
    return isNavigatingToPublicOrAuthPath(this.router.url.toString())
  }

  public get previousUrl() {
    return this._previousUrl;
  }

  public get currentUrl() {
    return this._currentUrl;
  }

  public get currentRoute(): ActivatedRoute {
    return this.activatedRoute;
  }

  public get currentId(): string {
    return this._currentId;
  }

  public get queryParams(): Params {
    return this.currentRoute.snapshot.queryParams;
  }

  public inSubSection(section: string): boolean {
    return this.router.url.includes(`/${section}/`);
  }

  get onShowPage(): boolean {
    const re = new RegExp(/\/\d+$/);
    return re.test(this.router.url);
  }

  public goToPreviousUrl() {
    return this.router.navigateByUrl(this.previousUrl);
  }

  public goToUrl(url) {
    return this.router.navigateByUrl(url);
  }

  public goToExternalUrl(url) {
    window.location.href = url;
  }

  public reloadComponent() {
    const currentPath = window.location.pathname;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentPath]);
    });
  }

  public createShowUrl(resources: string, id: number) {
    return '/' + [this.sectionRoot, resources, id].join('/');
  }

  public baseShowUrl(resources: string) {
    return '/' + [this.sectionRoot, resources].join('/') + '/';
  }

  public redirectToReturnUrl() {
    const returnUrl = this.queryParams && this.queryParams['returnUrl'];

    if (returnUrl && !returnUrl.startsWith('/login')) {
      return this.navigateTo(returnUrl);
    } else {
      return this.navigateHome();
    }
  }

  public redirectToLogin(queryParams = {}, skipReturnUrl = false, forcePageReload = false) {
    // NOTE: I tried to use RouterSnapshots, but they return UrlSegments for the url
    // and I couldn't find a way to stitch that info together. So, just asking the window
    // for the url.
    const returnUrl = window.location.pathname;
    const includeReturnUrl = !returnUrl.startsWith('/login') && !skipReturnUrl;

    let baseParams = {};

    if (includeReturnUrl) {
      baseParams = { returnUrl };
    }

    const qryParams = Object.assign(baseParams, queryParams);

    if (forcePageReload) {
      const qryString = Object.keys(qryParams)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(qryParams[key]))
        .join('&');

      window.location.href = qryString ? `/login?${qryString}` : '/login';
    } else {
      this.router.navigate(['/login'], { queryParams: qryParams });
    }
  }

  public navigateTo(path: string, replaceUrl = true) {
    return this.router.navigate([path], { replaceUrl });
  }

  public navigateHome() {
    return this.router.navigate(['/'], { replaceUrl: true });
  }

  public notFound() {
    return this.router.navigate(['/404'], { replaceUrl: true });
  }

  public maintenance() {
    return this.router.navigate(['/maintenance'], { replaceUrl: true });
  }

  public updateQueryParams(params, replaceUrl = false) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: params,
      replaceUrl,
    });
  }

  public reloadCurrentUrl() {
    location.reload();
  }

  public shouldReloadPage(object: { id: number }, id) {
    return object && id !== object.id.toString();
  }

  private get sectionRoot(): string {
    const curPathRoot = this.currentPathRoot;

    const sections: string[] = Object.values(SiteSection);

    if (sections.includes(curPathRoot)) {
      return curPathRoot;
    } else {
      return null;
    }
  }

  private get currentPathRoot(): string {
    const firstChildSnapshot: ActivatedRouteSnapshot = this.activatedRoute.snapshot.firstChild;

    if (firstChildSnapshot && firstChildSnapshot.url.length > 0) {
      return firstChildSnapshot.url[0].path;
    } else {
      return null;
    }
  }
}
