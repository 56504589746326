<app-import-nav />

<h5 class="mt-3">Claim Import History</h5>

<div class="container-fluid p-2 border rounded bg-light">
  <form class="form-inline">
    <div class="filter mb-3 me-1" style="max-width: 100%">
      <ng-select
        bindValue="id"
        bindLabel="humanizedName"
        name="selectedClientIds"
        placeholder="Filter by Client"
        [multiple]="true"
        [items]="clientFilterOptions"
        (remove)="onFilterChange()"
        (change)="onFilterChange()"
        [(ngModel)]="filters.selectedClientIds" />
    </div>
  </form>
</div>

<div *ngIf="!loading; else: spinner">
  <section class="mt-3" *ngIf="claimImports.length > 0; else: noImports">
    <div class="col-12">
      <app-pagination-header [paging]="paging" [label]="'imports'" (pageSizeChange)="onPageSizeChange($event)" />
    </div>
    <table class="table table-bordered table-hover">
      <thead class="table-dark">
        <tr>
          <th>CLIENT</th>
          <th>TPA</th>
          <th>STATUS</th>
          <th class="text-nowrap text-end">DATE IMPORTED</th>
          <th class="text-end">TOTAL ROWS</th>
          <th class="text-end">PROCESSED</th>
          <th class="text-end">SKIPPED</th>
          <th class="text-end">QUEUED</th>
          <th class="text-nowrap text-end">FILES</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let claimImport of claimImports">
          <td>{{ claimImport.client.name }}</td>
          <td>{{ claimImport.thirdPartyAdminister.name }}</td>
          <td>
            {{ claimImport.status | titlecase }}
            <fa-icon [hidden]="!claimImport.active" [icon]="['fas', 'circle-notch']" size="sm" [animation]="'spin'" />
            <div [hidden]="!claimImport.active">
              <button class="btn btn-sm btn-danger" (click)="onCancelClick($event, claimImport)">CANCEL</button>
            </div>
            <div [hidden]="!claimImport.failureMessage"
              class="btn btn-link"
              ngbPopover="{{ claimImport.failureMessage }}"
              triggers="mouseenter:mouseleave"
              popoverTitle="Errors">
                Errors
            </div>
          </td>
          <td class="text-end">
            {{ claimImport.createdAt | date: 'M/d/yyyy h:mm a' }}
            <div>
              <small>{{ claimImport.user.email }}</small>
            </div>
          </td>
          <td class="text-end">{{ claimImport.totalRows | number: '' }}</td>
          <td class="text-end">{{ claimImport.processed | number: '' }}</td>
          <td class="text-end">{{ claimImport.skipped | number: '' }}</td>
          <td class="text-end">{{ claimImport.imported | number: '' }}</td>
          <td class="text-end">
            <a [hidden]="!claimImport.hasSkippedFile"
                href="#downloadSkipped"
                (click)="onDownloadSkippedClick($event, claimImport)">
              Skipped Records File
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
  </section>
</div>
<ng-template #noImports>
  <section class="mt-3"><p>There are no imports.</p></section>
</ng-template>
<ng-template #spinner>
  <section class="mt-3"><mat-spinner diameter="30" /></section>
</ng-template>
