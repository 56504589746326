<div class="d-flex flex-column align-items-center text-center mt-3">
  <h2 class="text-center mt-3">340B Recovery Email Subscription</h2>

  <div *ngIf="!loading; else: spinner">

    <div *ngIf="error">
      <p class="text-danger text-center mt-3">{{ error }}</p>
    </div>

    <div *ngIf="!error">
      <p>Your email: <strong>{{ email }}</strong></p>

      <p *ngIf="unsubscribed">You have been unsubscribed.</p>

      <button (click)="toggleSubscription()" class="btn btn-primary">
        {{ unsubscribed ? 'Resubscribe' : 'Unsubscribe' }}
      </button>
    </div>

  </div>
</div>

<ng-template #spinner> <mat-spinner /> </ng-template>
