import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../api.service';
import { IccPharmacyImport } from '../../models/icc/icc-pharmacy-import.model';
import { IccImportFilters } from '../../models/icc/icc-import-settings.model';
import { PagedResults } from 'app/core/models/paged-results/paged-results.model';
import { LimitOffsetPagingMeta } from 'app/core/models/paged-results/limit-offset-paging.model';

export class PagedIccPharmacyImports implements PagedResults {
  pharmacyImports: IccPharmacyImport[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.pharmacyImports;
  }
}

@Injectable()
export class IccPharmacyImportsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  get(id): Observable<IccPharmacyImport> {
    return this.http.get<IccPharmacyImport>(`${environment.captureApi.url}/icc/pharmacy_imports/${id}`);
  }

  getList(
    paging: { page: number; pageSize: number } = null,
    filters: IccImportFilters = null
  ): Observable<PagedIccPharmacyImports> {
    const params = new URLSearchParams();

    if (filters && filters.selectedClientIds) {
      filters.selectedClientIds.forEach(clientId => params.append('client_ids[]', clientId));
    }

    if (paging) {
      params.append('page', paging.page.toString());
      params.append('per_page', paging.pageSize.toString());
    }

    return this.http.get<PagedIccPharmacyImports>(
      `${environment.captureApi.url}/icc/pharmacy_imports?${params.toString()}`
    );
  }

  create(clientId: number, name: string, signedId: string): Observable<IccPharmacyImport> {
    return this.http.post<IccPharmacyImport>(`${environment.captureApi.url}/icc/pharmacy_imports`, {
      pharmacyImport: { clientId, name, signedId },
    });
  }

  update(id, name): Observable<IccPharmacyImport> {
    return this.http.put<IccPharmacyImport>(`${environment.captureApi.url}/icc/pharmacy_imports/${id}`, {
      pharmacyImport: { name },
    });
  }

  cancel(id): Observable<IccPharmacyImport> {
    return this.http.patch<IccPharmacyImport>(`${environment.captureApi.url}/icc/pharmacy_imports/${id}/cancel`, {});
  }
}
