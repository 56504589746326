import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../api.service';
import { IccEligibleProviderImport } from '../../models/icc/icc-eligible-provider-import.model';
import { IccImportFilters } from '../../models/icc/icc-import-settings.model';
import { LimitOffsetPagingMeta } from 'app/core/models/paged-results/limit-offset-paging.model';
import { PagedResults } from 'app/core/models/paged-results/paged-results.model';

export class PagedIccEligibleProviderImports implements PagedResults {
  eligibleProviderImports: IccEligibleProviderImport[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.eligibleProviderImports;
  }
}

@Injectable()
export class IccEligibleProviderImportsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  get(id): Observable<IccEligibleProviderImport> {
    return this.http.get<IccEligibleProviderImport>(
      `${environment.captureApi.url}/icc/eligible_provider_imports/${id}`
    );
  }

  getList(
    paging: { page: number; pageSize: number } = null,
    filters: IccImportFilters = null
  ): Observable<PagedIccEligibleProviderImports> {
    const params = new URLSearchParams();

    if (filters && filters.selectedClientIds) {
      filters.selectedClientIds.forEach(clientId => params.append('client_ids[]', clientId));
    }

    if (paging) {
      params.append('page', paging.page.toString());
      params.append('per_page', paging.pageSize.toString());
    }

    return this.http.get<PagedIccEligibleProviderImports>(
      `${environment.captureApi.url}/icc/eligible_provider_imports?${params.toString()}`
    );
  }

  create(clientId: number, name: string, signedId: string): Observable<IccEligibleProviderImport> {
    return this.http.post<IccEligibleProviderImport>(`${environment.captureApi.url}/icc/eligible_provider_imports`, {
      eligibleProviderImport: { clientId, name, signedId },
    });
  }

  update(id, name): Observable<IccEligibleProviderImport> {
    return this.http.put<IccEligibleProviderImport>(
      `${environment.captureApi.url}/icc/eligible_provider_imports/${id}`,
      {
        eligibleProviderImport: { name },
      }
    );
  }

  cancel(id): Observable<IccEligibleProviderImport> {
    return this.http.patch<IccEligibleProviderImport>(
      `${environment.captureApi.url}/icc/eligible_provider_imports/${id}/cancel`,
      {}
    );
  }
}
