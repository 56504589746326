<app-import-nav />

<div *ngIf="!loading; else: spinner">
  <section class="mt-3" *ngIf="outboundFaxImports.length > 0; else: noFaxImports">
    <div class="col-12">
      <app-pagination-header [paging]="paging" [label]="'imports'" (pageSizeChange)="onPageSizeChange($event)" />
    </div>
    <table class="table table-bordered table-hover">
      <thead class="table-dark">
        <tr>
          <th>STATUS</th>
          <th class="text-nowrap text-end">FILES</th>
          <th class="text-nowrap text-end">IMPORTED AT &#x25BC;</th>
          <th class="text-end fax-status-col">TOTAL FAXES</th>
          <th class="text-end fax-status-col">SCHEDULED</th>
          <th class="text-end fax-status-col">WILL RETRY</th>
          <th class="text-end fax-status-col">SKIPPED</th>
          <th class="text-end fax-status-col">SENDING NOW</th>
          <th class="text-end fax-status-col">SENT</th>
          <th class="text-end fax-status-col">FAILED</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let outboundFaxImport of outboundFaxImports; trackBy: trackImportItemsBy">
          <td [attr.title]="outboundFaxImport.status === 'failed' ? outboundFaxImport.failureMessage : null">
            <div class="text-nowrap">
              {{ outboundFaxImport.status | titlecase }}
              <fa-icon
                [hidden]="!outboundFaxImport.active"
                [icon]="['fas', 'circle-notch']"
                size="sm"
                [animation]="'spin'"
              />
            </div>
            <div [hidden]="!outboundFaxImport.cancelable">
              <button class="btn btn-sm btn-danger" (click)="onCancelClick($event, outboundFaxImport)">
                CANCEL
              </button>
            </div>
          </td>
          <td class="text-end">
            <small>
              <div ngbDropdown class="d-inline-block">
                <button id="downloadDropdown" class="btn btn-sm btn-secondary" ngbDropdownToggle>Download</button>
                <div ngbDropdownMenu aria-labelledby="downloadDropdown">
                  <a
                    class="dropdown-item btn-sm"
                    href="#downloadOutcome"
                    *ngIf="skippedAndOutcomeFilesAvailable(outboundFaxImport)"
                    (click)="onDownloadOutcomesClick($event, outboundFaxImport)">
                    Fax Outcomes
                  </a>
                  <a
                    class="dropdown-item btn-sm"
                    href="#downloadSkipped"
                    *ngIf="skippedAndOutcomeFilesAvailable(outboundFaxImport)"
                    (click)="onDownloadSkippedClick($event, outboundFaxImport)">
                    Skipped Faxes
                  </a>
                  <a
                    class="dropdown-item btn-sm"
                    href="#downloadOriginal"
                    (click)="onDownloadOriginalClick($event, outboundFaxImport)" >
                    Fax Merge File
                  </a>
                </div>
              </div>
            </small>
          </td>
          <td class="text-end">
            {{ outboundFaxImport.createdAt | date: 'M/d/yyyy h:mm a' }}
            <div>
              <small>{{ outboundFaxImport.username }}</small>
            </div>
          </td>
          <td class="text-end">{{ outboundFaxImport.totalRows | number: '' }}</td>
          <td class="text-end">{{ outboundFaxImport.scheduledWithoutRetries | number: '' }}</td>
          <td class="text-end">{{ outboundFaxImport.scheduledWithRetries | number: '' }}</td>
          <td class="text-end">{{ outboundFaxImport.skipped | number: '' }}</td>
          <td class="text-end">{{ outboundFaxImport.queued | number: '' }}</td>
          <td class="text-end">{{ outboundFaxImport.sent | number: '' }}</td>
          <td class="text-end">{{ outboundFaxImport.failed | number: '' }}</td>
        </tr>
      </tbody>
    </table>
    <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
  </section>
</div>
<ng-template #noFaxImports>
  <section class="mt-3"><p>There are no imports.</p></section>
</ng-template>
<ng-template #spinner>
  <section class="mt-3"><mat-spinner diameter="30" /></section>
</ng-template>
