import { Component, Input, OnInit } from '@angular/core';
import { Capture } from '../../../core/models/capture.model';
import { Candidate } from '../../../core/models/candidate.model';
import { Encounter } from '../../../core/models/encounter.model';
import { Referral } from '../../../core/models/referral.model';
import { PatientService } from '../../../core/services/patient.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-validate-patient-care-ownership',
  templateUrl: './validate-patient-care-ownership.component.html',
  styleUrls: ['./validate-patient-care-ownership.component.scss'],
})
export class ValidatePatientCareOwnershipComponent implements OnInit {
  @Input() capture: Capture;

  candidate: Candidate;
  closestPatientInteractionEhrIdentifier: string;
  closestPatientInteractionDate: string;
  closestPatientInteractionType: string;
  closestPatientInteractionProviderName: string;
  closestPatientInteractionLocation: string;
  closestPatientInteractionEncounterType: string;
  closestPatientInteractionValid: boolean;
  patientInteractionIsVerified: boolean;
  eligibleEncounters: Encounter[] = [];
  ineligibleEncounters: Encounter[] = [];
  currentTab = 'eligibleEncounters';
  showIneligibleEncounters = false;
  moreEncountersThanDisplayed = false;

  constructor(private patientService: PatientService) {}

  ngOnInit() {
    this.candidate = this.capture.candidate;
    if (this.capture.verifyingEncounter) {
      this.setClosestPatientInteractionDataFromEncounter(this.capture.verifyingEncounter, true);
    } else if (this.capture.closestEncounter) {
      this.setClosestPatientInteractionDataFromEncounter(
        this.capture.closestEncounter,
        this.capture.closestEncounterValid
      );
    }
    this.patientInteractionIsVerified = !!this.capture.verifyingEncounter || !!this.capture.verifyingReferral;
    this.loadEncounters();
  }

  setClosestPatientInteractionDataFromEncounter(encounter: Encounter, valid: boolean) {
    this.closestPatientInteractionType = 'ENCOUNTER';
    this.closestPatientInteractionEhrIdentifier = encounter.ehrIdentifier;
    this.closestPatientInteractionDate = encounter.encounterDate;
    this.closestPatientInteractionProviderName = this.encounterProviderFullName(encounter);
    this.closestPatientInteractionLocation = encounter.location;
    this.closestPatientInteractionEncounterType = encounter.encounterType;
    this.closestPatientInteractionValid = valid;
  }

  loadEncounters(): void {
    const patientId = this.capture.patient.id;

    this.patientService.getEncounters(patientId).subscribe(
      result => {
        if (result.meta.eligibilityBasedOnLocation) {
          this.showIneligibleEncounters = true;

          this.eligibleEncounters =
            result.encounters.filter(encounter => encounter.locationEligibleDuringServiceDate);

          this.ineligibleEncounters =
            result.encounters.filter(encounter => !encounter.locationEligibleDuringServiceDate);
        } else {
          this.showIneligibleEncounters = false;
          this.eligibleEncounters = result.encounters;
        }
        if (result.meta.paging.count > result.meta.paging.pageSize) {
          this.moreEncountersThanDisplayed = true;
        }
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      }
    );
  }

  encounterProviderFullName(encounter: Encounter) {
    return `${encounter.providerFirst} ${encounter.providerLast} (NPI ${encounter.providerNpi})`;
  }

  referralProviderFullName(referral: Referral) {
    return `${referral.sendingProviderFirst} ${referral.sendingProviderLast} (NPI ${referral.sendingProviderNpi})`;
  }

  get serviceDateRangeInMonths(): number {
    return this.capture.client.serviceDateRangeInMonths;
  }

  tabClick($event, tabName) {
    $event.preventDefault();
    this.currentTab = tabName;
  }
}
