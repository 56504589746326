import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UnsubscribedEmailsService } from '../core/services/unsubscribed_emails.service';

@Component({
  selector: 'app-email-unsubscribe',
  templateUrl: './email-unsubscribe.component.html',
})
export class EmailUnsubscribeComponent implements OnInit {
  email: string;
  signature: string;
  unsubscribed: boolean;
  error: string;
  loading = true;

  constructor(
    private route: ActivatedRoute,
    private unsubscribedEmailService: UnsubscribedEmailsService
  ) { }

  ngOnInit() {
    this.init();
  }

  private init() {
    this.signature = this.route.snapshot.paramMap.get('signature');
    this.getStatus()
  }

  private getStatus() {
    this.unsubscribedEmailService.getStatus(this.signature).subscribe(result => {
      this.email = result.email;
      this.unsubscribed = result.unsubscribed;
      this.loading = false;
    },
      error => {
        console.log(error.message)
        this.error = "An error occured. The unsubscribe link may be expired or invalid."
        this.loading = false;
      }
    );
  }

  toggleSubscription() {
    const action = this.unsubscribed ?
      this.unsubscribedEmailService.resubscribe(this.signature) :
      this.unsubscribedEmailService.unsubscribe(this.signature);

    action.subscribe(
      () => {
        this.unsubscribed = !this.unsubscribed;
      },
      error => {
        console.log(error.message)
        this.error = "An error occurred. Please try again.";
      }
    );
  }
}
