import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReferralPrescriberSpecialtyMappingImport } from '../../../core/models/referral-prescriber-specialty-mapping-import.model';
import { ReferralPrescriberSpecialtyMappingService } from '../../../core/services/referral-prescriber-specialty-mapping.service';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';

@Component({
  selector: 'app-capture-admin-import-referral-prescriber-specialty-mappings-history',
  templateUrl: './capture-admin-import-referral-prescriber-specialty-mappings-history.component.html',
})
export class CaptureAdminImportReferralPrescriberSpecialtyMappingHistoryComponent implements OnInit, OnDestroy {
  loading = false;
  referralPrescriberSpecialtyMappingImports: ReferralPrescriberSpecialtyMappingImport[] = [];
  nextCheckTimeout = null;
  paging: LimitOffsetPaging;

  constructor(private referralPrescriberSpecialtyMappingService: ReferralPrescriberSpecialtyMappingService) {}

  ngOnInit() {
    this.paging = LimitOffsetPaging.empty;
    this.paging.currentPage = 1;
    this.loadImports(this.paging.currentPage);
  }

  ngOnDestroy() {
    clearTimeout(this.nextCheckTimeout);
  }

  onPageSizeChange(size: number) {
    this.paging.pageSize = size;
    this.loadImports(this.paging.currentPage);
  }

  onPageChange(page: number) {
    this.loadImports(page);
  }

  private loadImports(page, loading = true) {
    this.loading = loading;
    const paging = { page, pageSize: this.paging.pageSize };
    this.referralPrescriberSpecialtyMappingService.getList(paging).subscribe(result => {
      this.referralPrescriberSpecialtyMappingImports = result.referralPrescriberSpecialtyMappingImports;
      this.paging = result.meta.paging;
      this.loading = false;

      const anyActive: boolean = this.referralPrescriberSpecialtyMappingImports.some(record => record.active);

      if (anyActive) {
        this.nextCheckTimeout = setTimeout(() => this.loadImports(this.paging.currentPage, false), 500);
      }
    });
  }
}
