<form class="generic-form" #clientForm="ngForm" (ngSubmit)="onFormSubmit(clientForm)">
  <h5>Client Information</h5>

  <div class="row g-0">
    <div class="col-4">
      <div class="mb-3">
        <label>Name (required)</label>
        <input id="name"
               name="name"
               class="form-control"
               [(ngModel)]="client.name"
               [disabled]="client.humanEdited"
               [ngClass]="invalidCss(clientForm, 'name')" />

        <div *ngIf="!client.humanEdited">
          Warning: Name can only be set once. Once saved you will not be able to edit this field.
        </div>

        <app-form-control-server-errors [form]="clientForm" controlName="name" />
      </div>
    </div>

    <div class="col-4">
      <div class="mb-3">
        <label>Full Name (required)</label>
        <input id="fullName"
               name="fullName"
               class="form-control"
               [ngClass]="invalidCss(clientForm, 'fullName')"
               [(ngModel)]="client.fullName" />

        <app-form-control-server-errors [form]="clientForm" controlName="fullName" />
      </div>
    </div>
  </div>

  <div class="row g-0">
    <div class="col-4">
      <div class="mb-3">
        <label>
          Claim Identifier Prefix
        </label>

        <ng-select
          *ngIf="client.inheritsIdentifiersFromSubClients"
          class="no-selectable-items single-row"
          #effectiveClaimIdentifierPrefixesSelect
          [disabled]="true"
          [multiple]="true"
          [(ngModel)]="client.effectiveClaimIdentifierPrefixes"
          name="effectiveClaimIdentifierPrefixes" />

        <input
          id="claimIdentifierPrefix"
          *ngIf="!client.inheritsIdentifiersFromSubClients"
          name="claimIdentifierPrefix"
          class="form-control"
          [disabled]="disableClaimIndentifierPrefixInput"
          [ngClass]="invalidCss(clientForm, 'claimIdentifierPrefix')"
          [(ngModel)]="client.claimIdentifierPrefix" />

        <div *ngIf="!client.inheritsIdentifiersFromSubClients">
          Warning: Claim identifier prefix can only be set once. Once saved you will not be able to edit this field.
        </div>

        <app-form-control-server-errors [form]="clientForm" controlName="claimIdentifierPrefix" />
      </div>
    </div>

    <div class="col-4">
      <div class="mb-3">
        <label>
          HRSA IDs
          <span *ngIf="!client.inheritsIdentifiersFromSubClients">
            (required)
          </span>
        </label>

        <ng-select
          class="no-selectable-items single-row"
          *ngIf="client.inheritsIdentifiersFromSubClients"
          #hrsaIdSelect
          [disabled]="true"
          [multiple]="true"
          [(ngModel)]="client.effectiveHrsaIdStrings"
          name="effectiveHrsaIdStrings" />

        <ng-container *ngIf="!client.inheritsIdentifiersFromSubClients">
          <ng-select [(ngModel)]="client.hrsaIdStrings"
            class="no-selectable-items"
            #hrsaIdSelect
            [addTag]="addHrsaId"
            [multiple]="true"
            [selectOnTab]="true"
            [isOpen]="false"
            [clearable]="false"
            [ngClass]="invalidCss(clientForm, 'hrsaIdStrings')"
            (blur)="onHrsaSelectBlur($event, hrsaIdSelect)"
            [(ngModel)]="client.hrsaIdStrings"
            name="hrsaIdStrings">
            <ng-container *ngFor="let hrsaIdString of client.hrsaIdStrings">
              <ng-option
                [value]="hrsaIdString"
                [disabled]="savedHrsaIdStrings.includes(hrsaIdString)"
              >{{hrsaIdString}}</ng-option>
            </ng-container>
          </ng-select>

          <div class="form-text">
            Warning: Once saved, a HRSA ID cannot be removed. New HRSA IDs may be added.
          </div>
        </ng-container>

        <app-form-control-server-errors [form]="clientForm" controlName="hrsaIdStrings" />
      </div>
    </div>
  </div>

  <div class="row g-0">
    <div class="col-4">
      <div class="mb-3">
        <label>Super Client</label>

        <ng-select
          class="single-row"
          bindValue="id"
          bindLabel="humanizedName"
          name="superClientId"
          placeholder="Select the Super Client"
          [multiple]="false"
          [items]="clients"
          [(ngModel)]="client.superClientId"
          [ngClass]="invalidCss(clientForm, 'superClientId')"
        />

        <app-form-control-server-errors [form]="clientForm" controlName="superClientId" />
      </div>
    </div>
  </div>

  <h5>Deal Information</h5>

  <div class="row g-0">
    <div class="col-4">
      <div class="mb-3">
        <label>Date Deal Closed</label>
        <input
          name="dateDealClosed"
          class="form-control"
          type="date"
          autocomplete="off"
          [ngClass]="invalidCss(clientForm, 'dateDealClosed')"
          placeholder="MM/DD/YYYY"
          [(ngModel)]="client.dateDealClosed"
          (blur)="onDateDealClosedBlur(clientForm)"
        />
        <app-form-control-server-errors [form]="clientForm" controlName="dateDealClosed" />
      </div>
    </div>


    <div class="col-4">
      <div class="mb-3">
        <label>Go Live Date</label>
        <input
          name="goLiveDate"
          class="form-control"
          type="date"
          autocomplete="off"
          [ngClass]="invalidCss(clientForm, 'goLiveDate')"
          placeholder="MM/DD/YYYY"
          [(ngModel)]="client.goLiveDate"
          (blur)="onGoLiveDateBlur(clientForm)"
        />
        <app-form-control-server-errors [form]="clientForm" controlName="goLiveDate" />
      </div>
    </div>
  </div>

  <div class="row g-0">
    <div class="col-4">
      <label><strong>Annual Contract Value</strong></label>
      <div class="input-group estimated-value">
        <div class="input-group-prepend"><span class="input-group-text">$</span></div>
        <input
          [(ngModel)]="client.annualContractValue"
          name="annualContractValue"
          class="form-control pe-0"
          placeholder="e.g. 1000"
        />
      </div>
    </div>

    <div class="col-4">
      <label><strong>1st Year Estimated Value</strong></label>
      <div class="input-group estimated-value">
        <div class="input-group-prepend"><span class="input-group-text">$</span></div>
        <input
          [(ngModel)]="client.firstYearEstimatedValue"
          name="firstYearEstimatedValue"
          class="form-control pe-0"
          placeholder="e.g. 1000"
        />
      </div>
    </div>
  </div>

  <div class="row g-0">
    <div class="col-4">
      <label><strong>Adjustment</strong></label>
      <div class="input-group estimated-value">
        <div class="input-group-prepend"><span class="input-group-text">$</span></div>
        <input
          [(ngModel)]="client.adjustment"
          name="adjustment"
          class="form-control pe-0"
          placeholder="e.g. 1000"
        />
      </div>
    </div>
  </div>

  <h5>Address & Contact</h5>

  <div class="row g-0">
    <div class="col-4">
      <div class="mb-3">
        <label>Address Line 1</label>
        <input id="address1"
               name="address1"
               class="form-control"
               [ngClass]="invalidCss(clientForm, 'address1')"
               [(ngModel)]="client.address1" />

        <app-form-control-server-errors [form]="clientForm" controlName="address1" />
      </div>
    </div>

    <div class="col-4">
      <div class="mb-3">
        <label>Address Line 2</label>
        <input id="address2"
               name="address2"
               class="form-control"
               [ngClass]="invalidCss(clientForm, 'address2')"
               [(ngModel)]="client.address2" />

        <app-form-control-server-errors [form]="clientForm" controlName="address2" />
      </div>
    </div>
  </div>

  <div class="row g-0">
    <div class="col-4">
      <div class="mb-3">
        <label>City</label>
        <input id="city"
               name="city"
               class="form-control"
               [ngClass]="invalidCss(clientForm, 'city')"
               [(ngModel)]="client.city" />

        <app-form-control-server-errors [form]="clientForm" controlName="city" />
      </div>
    </div>

    <div class="col-4">
      <div class="mb-3">
        <label>State</label>

        <select id="state"
                name="state"
                class="form-select"
                [ngClass]="invalidCss(clientForm, 'state')"
                [(ngModel)]="client.state">
          <option [value]=""></option>
          <option *ngFor="let state of states | keyvalue"
                  [value]="state.key">
            {{ state.value }}
          </option>
        </select>

        <app-form-control-server-errors [form]="clientForm" controlName="state" />
      </div>
    </div>
  </div>

  <div class="row g-0">
    <div class="col-4">
      <div class="mb-3">
        <label>Zip Code</label>
        <input id="zip"
               name="zip"
               class="form-control"
                [ngClass]="invalidCss(clientForm, 'zip')"
                [(ngModel)]="client.zip" />

        <app-form-control-server-errors [form]="clientForm" controlName="zip" />
      </div>
    </div>
  </div>

  <h5>Claim Types</h5>

  <div class="row g-0">
    <div class="col-4">
      <div class="mb-3">
        <label>Applicable to Claim Types (required)</label>

        <div>
          <input type="checkbox"
                 id="applicableToReferralClaims"
                 name="applicableToReferralClaims"
                 value="net"
                 (click)="onClaimTypeClick($event, clientForm)"
                 [ngClass]="invalidCss(clientForm, 'applicableToReferralClaims')"
                 [(ngModel)]="client.applicableToReferralClaims" />
          <label class="checkbox-option" for="applicableToReferralClaims">Referral</label>

          <input type="checkbox"
                 id="applicableToIccClaims"
                 name="applicableToIccClaims"
                 (click)="onClaimTypeClick($event, clientForm)"
                 [ngClass]="invalidCss(clientForm, 'applicableToIccClaims')"
                 [(ngModel)]="client.applicableToIccClaims" />
          <label class="checkbox-option" for="applicableToIccClaims">Ineligible Claims Capture</label>

          <app-form-control-server-errors [form]="clientForm" controlName="applicableToReferralClaims" />
          <app-form-control-server-errors [form]="clientForm" controlName="applicableToIccClaims" />
        </div>

      </div>
    </div>
  </div>

  <h5>Invoicing Information</h5>

  <div class="row g-0">
    <div class="col-4">
      <div class="mb-3">
        <label>Invoicing Terms</label>

        <div>
          <label class="radio-option">
            <input type="radio"
                   id="invoicingTermsNet"
                   name="invoicingTerms"
                   value="net"
                   [ngClass]="invalidCss(clientForm, 'invoicingTerms')"
                   [(ngModel)]="client.invoicingTerms" />
            <span>Net</span>
          </label>

          <label class="radio-option">
            <input type="radio"
                   id="invoicingTermsGross"
                   name="invoicingTerms"
                   value="gross"
                   [ngClass]="invalidCss(clientForm, 'invoicingTerms')"
                   [(ngModel)]="client.invoicingTerms" />
            <span>Gross</span>
          </label>

          <label class="radio-option">
            <input type="radio"
                   id="invoicingTermsGross"
                   name="invoicingTerms"
                   value="flat"
                   [ngClass]="invalidCss(clientForm, 'invoicingTerms')"
                   [(ngModel)]="client.invoicingTerms" />
            <span>Flat</span>
          </label>
        </div>

        <app-form-control-server-errors [form]="clientForm" controlName="invoicingTerms" />
      </div>
    </div>

    <div class="col-4">
      <div class="mb-3">
        <label>Invoicing Terms Percentage</label>
        <input id="invoicingTermsRate"
               name="invoicingTermsRate"
               class="form-control"
               [ngClass]="invalidCss(clientForm, 'invoicingTermsRate')"
               [(ngModel)]="client.invoicingTermsRate" />

        <app-form-control-server-errors [form]="clientForm" controlName="invoicingTermsRate" />
      </div>
    </div>
  </div>

  <h5>ESP</h5>

  <div class="row g-0">
    <div class="col-4">
      <div class="mb-3">
        <label>Submitting to ESP?</label>
        <div>
          <label class="radio-option">
            <input type="radio"
                   id="submittingEspYes"
                   name="submittingToEsp"
                   [value]="true"
                   [ngClass]="invalidCss(clientForm, 'submittingToEsp')"
                   [(ngModel)]="client.submittingToEsp" />
            <span>Yes</span>
          </label>

          <label class="radio-option">
            <input type="radio"
                   id="submittingEspNo"
                   name="submittingToEsp"
                   [value]="false"
                   [ngClass]="invalidCss(clientForm, 'submittingToEsp')"
                   [(ngModel)]="client.submittingToEsp" />
            <span>No</span>
          </label>
        </div>
      </div>
    </div>

    <div class="col-4">
      <div class="mb-3">
        <label>ESP Submit Start Date</label>
        <input
          name="espSubmitStartDate"
          class="form-control"
          type="date"
          autocomplete="off"
          [ngClass]="invalidCss(clientForm, 'espSubmitStartDate')"
          placeholder="MM/DD/YYYY"
          [(ngModel)]="client.espSubmitStartDate"
          (blur)="onEspSubmitStartDateBlur(clientForm)"
        />
        <app-form-control-server-errors [form]="clientForm" controlName="espSubmitStartDate" />
      </div>
    </div>
  </div>

  <div class="row g-0">
    <div class="col-4">
      <label>ESP NDCs</label>
      <div class="alert alert-danger" *ngIf="hasEspNdcsFileErrors">
        There was a problem attaching the ESP NDCs file.
        <ul class="mt-2">
          <li *ngFor="let error of espNdcsFileErrors">{{ error }}</li>
        </ul>
      </div>
      <div class="input-group">
        <input type="file" id="espNdcs" accept=".csv" class="form-control" (change)="onEspNdcsFileChange($event)" />
      </div>

      <div *ngIf="client.espNdcsFilename" class="mt-3">
        <div *ngIf="!deleteEspNdcsFile"><strong>{{ client.espNdcsFilename }}</strong> <a href="#" (click)="onDeleteEspNdcsFile($event)">delete</a></div>
        <div *ngIf="deleteEspNdcsFile" class="alert alert-danger">
          <strong>{{ client.espNdcsFilename }}</strong> file is marked for deletion and will be deleted when the client is saved.
          <div>
            <a href="#" (click)="onUndoDeleteEspNdcsFile($event)">Undo Deletion</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <h5>Direct Messaging</h5>

  <div class="row g-0">
    <div class="col-4">
      <div class="mb-3">
        <label>Deliver Consult Notes Via Direct</label>

        <div>
          <label class="radio-option">
            <input type="radio"
                   id="directEnabledYes"
                   name="directEnabled"
                   [value]="true"
                   [ngClass]="invalidCss(clientForm, 'directEnabled')"
                   [(ngModel)]="client.directEnabled" />
            <span>Yes</span>
          </label>

          <label class="radio-option">
            <input type="radio"
                   id="directEnabledNo"
                   name="directEnabled"
                   [value]="false"
                   [ngClass]="invalidCss(clientForm, 'directEnabled')"
                   [(ngModel)]="client.directEnabled" />
            <span>No</span>
          </label>
        </div>

        <app-form-control-server-errors [form]="clientForm" controlName="directEnabled" />
      </div>
    </div>

    <div class="col-4">
      <div class="mb-3">
        <label>Client Receiving Address</label>
        <input id="directReceivingAddress"
               name="directReceivingAddress"
               class="form-control"
               [ngClass]="invalidCss(clientForm, 'directReceivingAddress')"
               [(ngModel)]="client.directReceivingAddress" />

        <app-form-control-server-errors [form]="clientForm" controlName="directReceivingAddress" />
      </div>
    </div>
  </div>

  <h5>Data Sharing</h5>

  <div class="row g-0">
    <div class="col-4">
      <div class="mb-3">
        <label>Does the client agree to share their data for PRN analysis?</label>

        <div>
          <label class="radio-option">
            <input type="radio"
                   id="allowResearchDataSharingYes"
                   name="allowResearchDataSharing"
                   [value]="true"
                   [ngClass]="invalidCss(clientForm, 'allowResearchDataSharing')"
                   [(ngModel)]="client.allowResearchDataSharing" />
            <span>Yes</span>
          </label>

          <label class="radio-option">
            <input type="radio"
                   id="allowResearchDataSharingNo"
                   name="allowResearchDataSharing"
                   [value]="false"
                   [ngClass]="invalidCss(clientForm, 'allowResearchDataSharing')"
                   [(ngModel)]="client.allowResearchDataSharing" />
            <span>No</span>
          </label>
        </div>

        <app-form-control-server-errors [form]="clientForm" controlName="allowResearchDataSharing" />
      </div>
    </div>
  </div>

  <h5>Allowed Client File EHR Instance Values</h5>
  <div class="row g-0">
    <div class="col-4">
      <ng-select
        class="no-selectable-items single-row"
        *ngIf="client.inheritsIdentifiersFromSubClients"
        #allowedClientFileEhrInstanceValuesSelect
        [disabled]="true"
        [multiple]="true"
        [(ngModel)]="allowedClientFileEhrInstanceValuesForDisplay"
        name="effectiveAllowedClientFileEhrInstanceValues" />

      <ng-select
        class="no-selectable-items"
        *ngIf="!client.inheritsIdentifiersFromSubClients"
        #allowedClientFileEhrInstanceValuesSelect
        [addTag]="addEhrInstance"
        [multiple]="true"
        [selectOnTab]="true"
        [isOpen]="false"
        [clearable]="false"
        [ngClass]="invalidCss(clientForm, 'allowedClientFileEhrInstanceValues')"
        [(ngModel)]="allowedClientFileEhrInstanceValuesForDisplay"
        (change)="onAllowedClientFileEhrInstanceValuesChange($event)"
        (blur)="onEhrInstanceSelectBlur($event, allowedClientFileEhrInstanceValuesSelect)"
        name="allowedClientFileEhrInstanceValues" />
    </div>
  </div>

  <h5>Fax Numbers</h5>

  <div class="row g-0">
    <div class="col-4">
      <div class="mb-3">
        <label>Local Fax Number</label>
        <input id="faxLocal"
               name="faxLocal"
               class="form-control"
               [ngClass]="invalidCss(clientForm, 'faxLocal')"
               [(ngModel)]="client.faxLocal" />

        <app-form-control-server-errors [form]="clientForm" controlName="faxLocal" />
      </div>
    </div>

    <div class="col-4">
      <div class="mb-3">
        <label>Toll Free Fax Number</label>
        <input id="faxTollfree"
               name="faxTollfree"
               class="form-control"
               [ngClass]="invalidCss(clientForm, 'faxTollfree')"
               [(ngModel)]="client.faxTollfree" />

        <app-form-control-server-errors [form]="clientForm" controlName="faxTollfree" />
      </div>
    </div>
  </div>

  <h5>Logo</h5>

  <div class="row g-0">
    <div class="col-4">
      <label>Client Logo</label>

      <div class="alert alert-danger" *ngIf="hasLogoFileErrors">
        There was a problem attaching the logo.
        <ul class="mt-2">
          <li *ngFor="let error of logoFileErrors">{{ error }}</li>
        </ul>
      </div>

      <div class="mb-3">
        <input id="fileInput"
               accept="image/*"
               type="file"
               class="form-control"
               (change)="onLogoFileChange($event)" />
      </div>

      <div *ngIf="client.logoUrl" class="mt-3">client
        <img src="{{ client.logoUrl }}" style="max-height: 100px;"  />
        <div><a href="#" (click)="onDeleteLogo($event)">delete</a></div>
      </div>
    </div>
  </div>

  <h5>Client File Monitoring</h5>

  <div class="row g-0">
    <div class="col-4">
      <label>Email Addresses</label>

      <ng-select class="no-selectable-items"
                 #clientFileMonitoringEmailRecipientsSelect
                 [addTag]="addClientFileMonitoringEmailRecipient"
                 [multiple]="true"
                 [selectOnTab]="true"
                 [isOpen]="false"
                 [clearable]="false"
                 [ngClass]="invalidCss(clientForm, 'clientFileMonitoringEmailRecipients')"
                 [(ngModel)]="client.clientFileMonitoringEmailRecipients"
                 (blur)="onClientFileMonitoringEmailRecipientsSelectBlur($event, clientFileMonitoringEmailRecipientsSelect)"
                 name="clientFileMonitoringEmailRecipients" />

      <app-form-control-server-errors [form]="clientForm" controlName="clientFileMonitoringEmailRecipients" />
    </div>
  </div>

  <div class="row g-0">
    <div class="col-4">
      <div class="mb-3">
        <label>File Processing Enabled?</label>

        <div>
          <label class="radio-option">
            <input type="radio"
                   [disabled]="!canManageImplementation"
                   id="fileProcessingEnabledYes"
                   name="fileProcessingEnabled"
                   [value]="true"
                   [ngClass]="invalidCss(clientForm, 'fileProcessingEnabled')"
                   [(ngModel)]="client.fileProcessingEnabled" />
            <span>Yes</span>
          </label>

          <label class="radio-option">
            <input type="radio"
                   [disabled]="!canManageImplementation"
                   id="fileProcessingEnabledNo"
                   name="fileProcessingEnabledAccess"
                   [value]="false"
                   [ngClass]="invalidCss(clientForm, 'fileProcessingEnabledAccess')"
                   [(ngModel)]="client.fileProcessingEnabled" />
            <span>No</span>
          </label>
        </div>
      </div>
    </div>
  </div>

  <h5>Users Assigned To Manage Client</h5>
  <div class="row g-0">
    <div class="col-4">
      <ng-select
        class="select-users"
        bindLabel="email"
        placeholder="Select User(s)"
        [multiple]="true"
        [items]="users"
        [(ngModel)]="client.assignedUsers"
        [ngClass]="invalidCss(clientForm, 'assignedUsers')"
        name="assignedUsers"
        [disabled]="!canManageUsers">
      >
      </ng-select>
    </div>
  </div>

  <h5>Third Party Administers</h5>
  <div *ngIf="duplicateThirdPartyAdministers" class="row">
    <div class="alert alert-danger col-4 mb-2">There's at least one Third Party Administer in both lists.</div>
  </div>
  <div class="row g-0">
    <div class="col-4">
      <label>Live</label>
      <ng-select
        bindLabel="name"
        placeholder="Select TPA(s)"
        [multiple]="true"
        [items]="thirdPartyAdministers"
        [(ngModel)]="client.liveThirdPartyAdministers"
        [ngClass]="invalidCss(clientForm, 'liveThirdPartyAdministers')"
        name="liveThirdPartyAdministers"
      />
    </div>
  </div>

  <div class="row g-0">
    <div class="col-4">
      <label>Contracted</label>
      <ng-select
        bindLabel="name"
        placeholder="Select TPA(s)"
        [multiple]="true"
        [items]="thirdPartyAdministers"
        [(ngModel)]="client.contractedThirdPartyAdministers"
        [ngClass]="invalidCss(clientForm, 'contractedThirdPartyAdministers')"
        name="contractedThirdPartyAdministers"
      />
    </div>
  </div>

  <h5>Entity Types</h5>
  <div class="row g-0">
    <div class="col-4">
      <ng-select
        placeholder="Select Entity Type(s)"
        [multiple]="true"
        [items]="entityTypes"
        [(ngModel)]="client.entityTypes"
        [ngClass]="invalidCss(clientForm, 'entityTypes')"
        name="entityTypes"
      />
    </div>
  </div>

  <div class="row g-0">
    <div class="col-8 actions-column">
      <div class="text-end actions">
        <button type="submit" class="btn btn-primary" [disabled]="saving">Save</button>
        <button type="button" class="btn btn-secondary" [disabled]="saving" (click)="onCancelClick()">Cancel</button>
      </div>
    </div>
  </div>
</form>
