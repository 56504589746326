import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpErrorResponse, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { ApplicationService } from 'app/core/services/application.service';
import { LogoutReason } from 'app/core/enums/logout-reason.enum';
import { environment } from '../../environments/environment';
import { AppTabIdSessionKey } from './constants';
import { SessionStorageService } from './services/session-storage.service';

@Injectable()
export class SessionTimeoutInterceptor implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService,
    private sessionStorageService: SessionStorageService,
    private applicationService: ApplicationService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.handleSessionExpiration(request, next);
  }

  private handleSessionExpiration(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(event => this.checkSessionExpiration(event)),
      catchError((httpErrorResponse: HttpErrorResponse) => {
        if (httpErrorResponse.status === 440) {
          this.logActivityMessage('Logging out due to session expiration response');
          this.authenticationService.logout(LogoutReason.inactivity);
        }

        return throwError(httpErrorResponse);
      })
    );
  }

  private checkSessionExpiration(event: HttpEvent<any>): void {
    if (event instanceof HttpResponse) {
      const sessionTimeoutSecondsHeader = event.headers.get('X-340B-SESSION-TIMEOUT');

      if (sessionTimeoutSecondsHeader) {
        this.applicationService.resetSessionCheckInTime();

        const logoutTimeoutMilliseconds = parseInt(sessionTimeoutSecondsHeader, 10) * 1000;
        this.applicationService.setSessionExpirationLogoutTime(logoutTimeoutMilliseconds);
      } else {
        this.logActivityMessage('Session timeout header not set!')
      }
    }
  }

  private logActivityMessage(msg: string) {
    if (environment.debugUserIdle) {
      console.log(`${msg} [${this.appTabId}] `);
    }
  }

  private get appTabId(): string {
    return this.sessionStorageService.get(AppTabIdSessionKey);
  }
}
