import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Referral } from '../models/referral.model';
import { Encounter } from '../models/encounter.model';
import { ApiService } from './api.service';
import { PatientSearchQuery } from '../models/patient-search-query.model';
import { Patient } from '../models/patient.model';
import { Prescriber } from '../models/prescriber.model';
import { OutboundFax } from '../models/outbound-fax.model';
import { PatientAttachmentListing } from '../models/patient-attachment-listing.model';
import { OutboundFaxStatus } from '../enums/outbound-fax-status.enum';
import { PagedResults } from '../models/paged-results/paged-results.model';
import { LimitOffsetPagingMeta, Paging } from '../models/paged-results/limit-offset-paging.model';
import { PatientFilters } from '../models/user-settings/patient-search-settings.model';

export class PagedPatients implements PagedResults {
  patients: Patient[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.patients;
  }
}

export class ReferralsResult {
  referrals: Referral[];
  meta: { eligibilityBasedOnLocation: boolean; }
}

export class EncountersResult {
  encounters: Encounter[];
  meta: LimitOffsetPagingMeta & { eligibilityBasedOnLocation: boolean; }
}

export class PagedPatientEncounters implements PagedResults {
  private encounters: Encounter[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.encounters;
  }
}

export class OutboundFaxesResult {
  outboundFaxes: OutboundFax[];
}

@Injectable()
export class PatientService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  search(
    patientSearchQuery: PatientSearchQuery,
    paging: Paging,
  ): Observable<PagedPatients> {
    const queryParams: any = this.toParams(patientSearchQuery);
    queryParams['page'] = paging.page;
    queryParams['per_page'] = paging.pageSize;

    return this.http.get<PagedPatients>(`${environment.captureApi.url}/patients/search`, {
      params: queryParams,
    });
  }

  getDocuments(patient_id: number): Observable<PatientAttachmentListing[]> {
    return this.http.get<PatientAttachmentListing[]>(`${environment.captureApi.url}/patients/${patient_id}/documents`);
  }

  getReferrals(patient_id: number): Observable<ReferralsResult> {
    const paging: Paging = { pageSize: 'all' }

    return this.http.get<ReferralsResult>(
      `${environment.captureApi.url}/patients/${patient_id}/referrals?page=${paging.page}&per_page=${paging.pageSize}`
    );
  }

  getEncounters(patient_id: number, since: string = null): Observable<EncountersResult> {
    let url = `${environment.captureApi.url}/patients/${patient_id}/encounters`;

    if (since) {
      url = `${url}?since=${since}`;
    }

    return this.http.get<EncountersResult>(url);
  }

  pagedEncounters(patient_id: number, eligible: boolean,
    paging: Paging,
  ): Observable<PagedPatientEncounters> {

    const searchParams = new URLSearchParams(`page=${paging.page}&per_page=${paging.pageSize}`);
    searchParams.append('eligible', eligible.toString());
    return this.http.get<PagedPatientEncounters>(
      `${environment.captureApi.url}/patients/${patient_id}/encounters?${searchParams.toString()}`
    ).pipe(
      map(result => Object.assign(new PagedPatientEncounters(), result))
    );
  }

  getPrescribers(patientId: number): Observable<Prescriber[]> {
    return this.http.get<Prescriber[]>(`${environment.captureApi.url}/patients/${patientId}/prescribers`);
  }

  getOutboundFaxes(
    patientId: number,
    statuses: OutboundFaxStatus[] = [OutboundFaxStatus.sent],
    limit = 10
  ): Observable<OutboundFaxesResult> {
    const searchParams = new URLSearchParams();

    searchParams.append('page', '1');
    searchParams.append('per_page', limit.toString());

    if (statuses && statuses.length > 0) {
      statuses.forEach(s => searchParams.append('statuses[]', s));
    }

    return this.http.get<OutboundFaxesResult>(
      `${environment.captureApi.url}/patients/${patientId}/outbound_faxes?&${searchParams.toString()}`
    );
  }

  getList(
    paging: Paging,
    filters: PatientFilters = null,
    includeCaptureCounts = false
  ): Observable<PagedPatients> {
    const searchParams = new URLSearchParams(`page=${paging.page}&per_page=${paging.pageSize}`);

    if (filters) {
      if (filters.clientId) {
        searchParams.append('client_id', filters.clientId.toString());
      }

      if (filters.patientId) {
        searchParams.append('id_or_main_patient_id', filters.patientId);
      }

      if (filters.patientFirstName) {
        searchParams.append('first_name', filters.patientFirstName);
      }

      if (filters.patientLastName) {
        searchParams.append('last_name', filters.patientLastName);
      }

      if (filters.patientDob) {
        searchParams.append('dob', filters.patientDob);
      }

      if (filters.patientMrn) {
        searchParams.append('mrn', filters.patientMrn);
      }
    }

    if (includeCaptureCounts) {
      searchParams.append('include_capture_counts', "true");
    }

    return this.http.get<PagedPatients>(
      `${environment.captureApi.url}/patients?${searchParams.toString()}`
    );
  }

  get(id: number): Observable<Patient> {
    return this.http.get<Patient>(`${environment.captureApi.url}/patients/${id}`);
  }

  deactivate(id: number, date: Date): Observable<Patient> {
    return this.http.patch<Patient>(`${environment.captureApi.url}/patients/${id}/deactivate`, { date });
  }

  getSsn(id: number): Observable<Patient> {
    return this.http.get<Patient>(`${environment.captureApi.url}/patients/${id}/ssn`);
  }

}
