import { Component, Inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DownloadService } from '../../../core/services/download.service';
import { IccImportHistoryComponent } from '../icc-import-history/icc-import-history.component';
import { IccPharmacyImportsService } from '../../../core/services/icc/icc-pharmacy-imports.service';
import { ClientsService } from '../../../core/services/clients.service';

@Component({
  selector: 'app-icc-import-pharmacies-history',
  templateUrl: '../icc-import-history/icc-import-history.component.html',
})
export class IccImportPharmaciesHistoryComponent extends IccImportHistoryComponent {
  pageTitle = 'Pharmacy Import History';

  constructor(
    protected downloadService: DownloadService,
    protected modalService: NgbModal,
    protected clientsService: ClientsService,
    private pharmacyImportsService: IccPharmacyImportsService
  ) {
    super(downloadService, modalService, clientsService);
  }

  getImports(paging, filters) {
    return this.pharmacyImportsService.getList(paging, filters);
  }

  prepareImportResults(data) {
    return data.pharmacyImports;
  }

  getImport(importId) {
    return this.pharmacyImportsService.get(importId);
  }

  updateImport(importId, name) {
    return this.pharmacyImportsService.update(importId, name);
  }

  cancelImport(importId) {
    return this.pharmacyImportsService.cancel(importId);
  }
}
