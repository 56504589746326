import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { isNull } from 'lodash-es';
import { NavigationService, isNavigatingToPublicOrAuthPath, isNavigatingToPublicPath } from '../../core/services/navigation.service';
import { AuthenticationService } from '../../core/services/authentication.service';
import { SiteSection } from '../../core/enums/site-section-enum';
import { SentryService } from '../../core/services/sentry.service';

const determineSiteSection = (route: ActivatedRouteSnapshot): SiteSection => {
  const sections: string[] = Object.values(SiteSection);
  const parentPath = route.parent && route.parent.url.toString();

  if (sections.includes(parentPath)) {
    return parentPath as SiteSection;
  } else {
    return null;
  }
};

@Injectable()
export class AuthGuard {
  constructor(
    private authService: AuthenticationService,
    private navigationService: NavigationService,
    private sentryService: SentryService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot) {
    if (this.authService.isInMaintenance) {
      return this.navigationService.maintenance();
    }

    return this.authService.verifySession().pipe(
      map(sessionVerified => {
        if (sessionVerified) {
          return this.canActivateForUser(route);
        } else {
          return this.canActivateForAnonymous(route);
        }
      })
    );
  }

  private canActivateForUser(route: ActivatedRouteSnapshot): boolean {
    const siteSection: SiteSection = determineSiteSection(route);

    if (siteSection === null) {
      const currentUrlPath = route.url.map(segment => segment.path).join('/')
      if (isNavigatingToPublicPath(currentUrlPath)) {
        return true;
      } else {
        this.navigateToAuthorizedSection();
        return false;
      }
    } else {
      return this.canActivateSiteSectionForUser(siteSection);
    }
  }

  private canActivateForAnonymous(route: ActivatedRouteSnapshot): boolean {
    const currentUrlPath = route.url.map(segment => segment.path).join('/')
    if (isNavigatingToPublicOrAuthPath(currentUrlPath)) { return true; }

    this.navigationService.redirectToLogin();
    return false;
  }

  private canActivateSiteSectionForUser(siteSection: SiteSection) {
    if (!this.isUserAuthorizedForSection(siteSection)) {
      this.navigateToAuthorizedSection();
      return false;
    } else {
      // continue to authorized site section page
      return true;
    }
  }

  private isUserAuthorizedForSection(siteSection: SiteSection): boolean {
    switch (siteSection) {
      case SiteSection.captureAdmin:
        return this.authService.isCaptureAdminUser;
      case SiteSection.cePortal:
        return this.authService.isClientUser;
      case SiteSection.partnerPortal:
        return this.authService.isPartnerUser;
      case SiteSection.llmPlayground:
        return this.authService.isLlmPlaygroundUser;
      default:
        return false;
    }
  }

  private navigateToAuthorizedSection() {
    if (this.authService.isCaptureAdminUser) {
      this.navigationService.navigateTo(SiteSection.captureAdmin);
    } else if (this.authService.isClientUser) {
      this.navigationService.navigateTo(SiteSection.cePortal);
    } else if (this.authService.isPartnerUser) {
      this.navigationService.navigateTo(SiteSection.partnerPortal);
    } else if (this.authService.isLlmPlaygroundUser) {
      this.navigationService.navigateTo(SiteSection.llmPlayground);
    } else {
      this.handleUnknownUserType();
    }
  }

  private handleUnknownUserType() {
    if (isNull(this.authService.currentUser)) {
      this.sentryService.logMessage('User profile has not been loaded');
    } else {
      this.sentryService.logMessage('User profile is not configured properly');
    }

    this.navigationService.notFound();
  }
}
