import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { PatientInteractionLocationImport } from '../models/patient-interaction-location-import.model';
import { LimitOffsetPagingMeta } from '../models/paged-results/limit-offset-paging.model';
import { PagedResults } from '../models/paged-results/paged-results.model';

export class PagedPatientInteractionLocationImports implements PagedResults {
  patientInteractionLocationImports: PatientInteractionLocationImport[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.patientInteractionLocationImports;
  }
}

@Injectable()
export class PatientInteractionLocationImportsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  get(id): Observable<PatientInteractionLocationImport> {
    return this.http.get<PatientInteractionLocationImport>(
      `${environment.captureApi.url}/patient_interaction_location_imports/${id}`
    );
  }

  getList(
    paging: { page: number; pageSize: number } = null,
  ): Observable<PagedPatientInteractionLocationImports> {
    const params = new URLSearchParams();

    if (paging) {
      params.append('page', paging.page.toString());
      params.append('per_page', paging.pageSize.toString());
    }

    return this.http.get<PagedPatientInteractionLocationImports>(
      `${environment.captureApi.url}/patient_interaction_location_imports?${params.toString()}`
    );
  }

  create(signedId: string): Observable<PatientInteractionLocationImport> {
    return this.http.post<PatientInteractionLocationImport>(
      `${environment.captureApi.url}/patient_interaction_location_imports`,
      {
        patientInteractionLocationImport: { signedId },
      }
    );
  }

  cancel(id): Observable<PatientInteractionLocationImport> {
    return this.http.patch<PatientInteractionLocationImport>(
      `${environment.captureApi.url}/patient_interaction_location_imports/${id}/cancel`,
      {}
    );
  }
}
