import { Component, OnDestroy, OnInit } from '@angular/core';
import { CaptureInvoiceLineItemImport } from '../../../core/models/capture-invoice-line-item-import.model';
import { CaptureInvoiceLineItemImportsService } from '../../../core/services/capture-invoice-line-item-imports.service';
import { DownloadService } from '../../../core/services/download.service';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';

@Component({
  selector: 'app-capture-admin-import-capture-invoice-line-items-history',
  templateUrl: './capture-admin-import-capture-invoice-line-items-history.component.html',
  styleUrls: ['./capture-admin-import-capture-invoice-line-items-history.component.scss'],
})
export class CaptureAdminImportCaptureInvoiceLineItemsHistoryComponent implements OnInit, OnDestroy {
  loading = false;
  captureInvoiceLineItemImports: CaptureInvoiceLineItemImport[] = [];
  nextCheckTimeout = null;
  paging: LimitOffsetPaging;

  constructor(
    private captureInvoiceLineItemImportsService: CaptureInvoiceLineItemImportsService,
    private downloadService: DownloadService
  ) {}

  ngOnInit() {
    this.paging = LimitOffsetPaging.empty;
    this.paging.currentPage = 1;
    this.loadImports(this.paging.currentPage);
  }

  ngOnDestroy() {
    clearTimeout(this.nextCheckTimeout);
  }

  onPageSizeChange(size: number) {
    this.paging.pageSize = size;
    this.loadImports(this.paging.currentPage);
  }

  onPageChange(page: number) {
    this.loadImports(page);
  }

  onCancelClick($event, captureInvoiceImport: CaptureInvoiceLineItemImport) {
    $event.preventDefault();
    this.captureInvoiceLineItemImportsService.cancel(captureInvoiceImport.id).subscribe(invoiceImport => {
      const index = this.captureInvoiceLineItemImports.findIndex(imp => imp.id === invoiceImport.id);

      if (index > -1) {
        this.captureInvoiceLineItemImports[index] = invoiceImport;
        this.captureInvoiceLineItemImports = [...this.captureInvoiceLineItemImports];
      }
    });
  }

  onDownloadOriginalClick($event, captureInvoiceImport: CaptureInvoiceLineItemImport) {
    $event.preventDefault();
    this.captureInvoiceLineItemImportsService.get(captureInvoiceImport.id).subscribe(invoiceImport => {
      this.downloadService.getDownloadUrl(invoiceImport.fileExpirableDownloadPath).subscribe(expirableDownload => {
        this.downloadService.download(expirableDownload.url);
      });
    });
  }

  onDownloadSkippedClick($event, captureInvoiceImport: CaptureInvoiceLineItemImport) {
    $event.preventDefault();
    this.captureInvoiceLineItemImportsService.get(captureInvoiceImport.id).subscribe(invoiceImport => {
      const downloadUrl = invoiceImport.skippedFileExpirableDownloadPath;
      this.downloadService.getDownloadUrl(downloadUrl).subscribe(expirableDownload => {
        this.downloadService.download(expirableDownload.url);
      });
    });
  }

  private loadImports(page, loading = true) {
    this.loading = loading;
    const paging = { page, pageSize: this.paging.pageSize };

    this.captureInvoiceLineItemImportsService.getList(paging).subscribe(result => {
      this.captureInvoiceLineItemImports = result.captureInvoiceLineItemImports;
      this.paging = result.meta.paging;
      this.loading = false;

      const anyActive: boolean = this.captureInvoiceLineItemImports.some(invoiceImport => invoiceImport.active);

      if (anyActive) {
        this.nextCheckTimeout = setTimeout(() => this.loadImports(this.paging.currentPage, false), 500);
      }
    });
  }
}
