<app-import-nav />

<h5 class="mt-3">{{ pageTitle }}</h5>

<div class="container-fluid p-2 border rounded bg-light" *ngIf="importsAssociatedToClients">
  <form class="form-inline">
    <div class="filter mb-3 me-1" style="max-width: 100%">
      <ng-select
        bindValue="id"
        bindLabel="humanizedName"
        name="selectedClientIds"
        placeholder="Filter by Client"
        [multiple]="true"
        [items]="clientFilterOptions"
        (remove)="onFilterChange()"
        (change)="onFilterChange()"
        [(ngModel)]="filters.selectedClientIds" />
    </div>
  </form>
</div>

<div *ngIf="!loading; else: spinner">
  <section class="mt-3" *ngIf="imports.length > 0; else: noImports">
    <div class="col-12">
      <app-pagination-header [paging]="paging" [label]="'imports'" (pageSizeChange)="onPageSizeChange($event)" />
    </div>
    <table class="table table-bordered table-hover">
      <thead class="table-dark">
        <tr>
          <th>FILE NAME</th>
          <th *ngIf="importsAssociatedToClients">CLIENT</th>
          <th *ngIf="showThirdPartyAdminister">TPA</th>
          <th>STATUS</th>
          <th class="text-nowrap text-end">DATE IMPORTED</th>
          <th class="text-end">TOTAL ROWS</th>
          <th class="text-end">PROCESSED</th>
          <th class="text-end">SKIPPED</th>
          <th class="text-end">IMPORTED</th>
          <th class="text-nowrap text-end">FILES</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let import of imports">
          <td style="max-width: 200px" class="wrap-with-hyphens">
            <div>{{ import.name }}</div>
            <div><a href="#editName" (click)="onImportNameEditClick($event, import)">Edit</a></div>
          </td>
          <td *ngIf="importsAssociatedToClients">{{ import.client.name }}</td>
          <td *ngIf="showThirdPartyAdminister">{{ import.thirdPartyAdminister.name }}</td>
          <td>
            {{ import.status | titlecase }}
            <fa-icon [hidden]="!import.active" [icon]="['fas', 'circle-notch']" size="sm" [animation]="'spin'" />
            <div [hidden]="!import.active">
              <button class="btn btn-sm btn-danger" (click)="onCancelClick($event, import)">CANCEL</button>
            </div>
            <div [hidden]="!import.failureMessage"
              class="btn btn-link"
              ngbPopover="{{ import.failureMessage }}"
              triggers="mouseenter:mouseleave"
              popoverTitle="Errors">
                Errors
            </div>
          </td>
          <td class="text-end">
            {{ import.createdAt | date: 'M/d/yyyy h:mm a' }}
            <div>
              <small>{{ import.user.email }}</small>
            </div>
          </td>
          <td class="text-end">{{ import.totalRows | number: '' }}</td>
          <td class="text-end">{{ import.processed | number: '' }}</td>
          <td class="text-end">{{ import.skipped | number: '' }}</td>
          <td class="text-end">{{ import.imported | number: '' }}</td>
          <td class="text-end">
            <small>
              <div ngbDropdown class="d-inline-block">
                <button id="downloadDropdown" class="btn btn-sm btn-secondary" ngbDropdownToggle>Download</button>
                <div ngbDropdownMenu aria-labelledby="downloadDropdown">
                  <a [hidden]="!import.hasSkippedFile"
                      class="dropdown-item btn-sm"
                      href="#downloadSkipped"
                      (click)="onDownloadSkippedClick($event, import)">
                    Skipped Records File
                  </a>

                  <a class="dropdown-item btn-sm"
                     href="#downloadOriginal"
                     (click)="onDownloadOriginalClick($event, import)">
                    Uploaded File
                  </a >
                </div>
              </div>
            </small>
          </td>
        </tr>
      </tbody>
    </table>
    <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
  </section>
</div>
<ng-template #noImports>
  <section class="mt-3"><p>There are no imports.</p></section>
</ng-template>
<ng-template #spinner>
  <section class="mt-3"><mat-spinner diameter="30" /></section>
</ng-template>
