import { Component, Input } from '@angular/core';
import { Capture } from '../../../core/models/capture.model';
import { CaptureService } from '../../../core/services/capture.service';

@Component({
  selector: 'app-capture-reprocess',
  templateUrl: './capture-reprocess.component.html',
  styleUrls: ['./capture-reprocess.component.scss'],
})
export class CaptureReprocessComponent {
  @Input() capture: Capture;

  constructor(private captureService: CaptureService) {}

  reprocessCaptureClick() {
    this.captureService.reprocess(this.capture).subscribe((modifiedCapture: Capture) => {
      this.captureService.notifyCaptureChanged(modifiedCapture);
    });
  }
}
