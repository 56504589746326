import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { ClaimImport } from '../models/claim-import.model';
import { ClaimImportFilters } from '../models/user-settings/claim-import-settings.model';
import { LimitOffsetPaging, LimitOffsetPagingMeta } from '../models/paged-results/limit-offset-paging.model';
import { PagedResults } from '../models/paged-results/paged-results.model';

export class PagedClaimImports implements PagedResults {
  claimImports: ClaimImport[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.claimImports;
  }
}

@Injectable()
export class ClaimImportsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  get(id): Observable<ClaimImport> {
    return this.http.get<ClaimImport>(`${environment.captureApi.url}/claim_imports/${id}`);
  }

  getList(
    paging: { page: number; pageSize: number } = null,
    filters: ClaimImportFilters = null
  ): Observable<PagedClaimImports> {
    const params = new URLSearchParams();

    if (filters && filters.selectedClientIds) {
      filters.selectedClientIds.forEach(clientId => params.append('client_ids[]', clientId));
    }

    if (paging) {
      params.append('page', paging.page.toString());
      params.append('per_page', paging.pageSize.toString());
    }

    return this.http.get<PagedClaimImports>(
      `${environment.captureApi.url}/claim_imports?${params.toString()}`
    );
  }

  create(clientId: number, thirdPartyAdministerName: string, signedId: string): Observable<ClaimImport> {
    return this.http.post<ClaimImport>(`${environment.captureApi.url}/claim_imports`, {
      claimImport: { clientId, thirdPartyAdministerName, name, signedId },
    });
  }

  cancel(id): Observable<ClaimImport> {
    return this.http.patch<ClaimImport>(`${environment.captureApi.url}/claim_imports/${id}/cancel`, {});
  }
}
