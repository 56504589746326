import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CuratedSpecialtiesImport } from '../models/curated-specialties-import.model';
import { PagedResults } from '../models/paged-results/paged-results.model';
import { LimitOffsetPagingMeta } from '../models/paged-results/limit-offset-paging.model';

export class PagedCuratedSpecialtiesImports implements PagedResults {
  curatedSpecialtiesImports: CuratedSpecialtiesImport[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.curatedSpecialtiesImports;
  }
}

@Injectable()
export class CuratedSpecialtiesService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  create(signedId): Observable<CuratedSpecialtiesImport> {
    return this.http.post<CuratedSpecialtiesImport>(`${environment.captureApi.url}/curated_specialties_imports`, {
      curatedSpecialtiesImport: { signedId },
    });
  }

  getList(
    paging: { page: number; pageSize: number } = null,
  ): Observable<PagedCuratedSpecialtiesImports> {
    const params = new URLSearchParams();

    if (paging) {
      params.append('page', paging.page.toString());
      params.append('per_page', paging.pageSize.toString());
    }

    return this.http.get<PagedCuratedSpecialtiesImports>(
      `${environment.captureApi.url}/curated_specialties_imports?${params.toString()}`
    );
  }
}
