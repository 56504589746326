import { Component, Input, OnChanges, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReportOption } from 'app/core/models/report-option.model';
import { ReportAbstractOptionComponent } from 'app/shared/components/report-abstract-option/report-abstract-option.component';
import { Client } from 'app/core/models/client.model';
import { IccClaimImport } from 'app/core/models/icc/icc-claim-import.model';
import { IccClaimImportsService } from 'app/core/services/icc/icc-claim-imports.service';

export class IccReportClaimImportOption implements ReportOption {
  value: IccClaimImport;

  constructor(value) {
    this.value = value;
  }

  toPostData(options = { paramName: 'iccClaimImportId' }) {
    return { [options.paramName]: this.value && this.value.id };
  }
}

@Component({
  selector: 'app-icc-report-claim-import-option',
  templateUrl: './icc-report-claim-import-option.component.html',
  styleUrls: ['./icc-report-claim-import-option.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => IccReportClaimImportOptionComponent),
    },
  ],
})
export class IccReportClaimImportOptionComponent extends ReportAbstractOptionComponent implements OnChanges {
  @Input() label = 'Claims File';
  @Input() clientId: number;

  selectedClaimImport: IccClaimImport;
  claimImports: IccClaimImport[];

  constructor(protected claimImportsService: IccClaimImportsService) {
    super();
  }

  ngOnChanges() {
    if (this.selectedClaimImport) {
      this.selectedClaimImport = null;
      this.onChange();
    }

    this.loadClaimImports();
  }

  private loadClaimImports() {
    this.claimImports = [];

    if (this.clientId) {
      this.claimImportsService.getList(null, { selectedClientIds: [this.clientId] }).subscribe(result => {
        this.claimImports = result.claimImports;
      });
    }
  }

  protected updateInternalState(model: IccReportClaimImportOption) {
    if (model) {
      this.selectedClaimImport = model.value;
    } else {
      this.selectedClaimImport = null;
    }
  }

  protected determineModelStateOnChange(): IccReportClaimImportOption {
    if (this.selectedClaimImport) {
      return new IccReportClaimImportOption(this.selectedClaimImport);
    } else {
      return null;
    }
  }
}
