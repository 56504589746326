<span
  placement="bottom"
  [disablePopover]="capture.allowReprocessing || !capture.reprocessingBatch"
  [ngbPopover]="popoverContent">

  <button
    class="btn btn-secondary"
    [disabled]="!capture.allowReprocessing"
    (click)="reprocessCaptureClick()">
    REPROCESS
  </button>
</span>

<ng-template #popoverContent>
  <ng-container *ngIf="!capture.allowReprocessing && capture.reprocessingBatch">
    Reprocessing queued at
    {{ capture.reprocessingBatch.createdAt | date: 'M/d h:mm a' }}
    <br />
    by
    {{ capture.reprocessingBatch.associatedModelType }}
    {{ capture.reprocessingBatch.associatedModelId }}
    ({{ capture.reprocessingBatch.action }})
  </ng-container>
</ng-template>
