import { Component, Input } from '@angular/core';
import { Patient } from '../../../core/models/patient.model';
import { HttpErrorResponse } from '@angular/common/http';
import { PatientService } from '../../../core/services/patient.service';
import { Encounter } from '../../../core/models/encounter.model';

@Component({
  selector: 'app-validate-patient-encounters',
  templateUrl: './validate-patient-encounters.component.html',
  styleUrls: ['./validate-patient-encounters.component.scss'],
})
export class ValidatePatientEncountersComponent {
  _patient: Patient;

  eligibleEncounters: Encounter[] = [];
  ineligibleEncounters: Encounter[] = [];
  currentTab = 'eligibleEncounters';
  showIneligibleEncounters = false;
  loading = false;
  moreEncountersThanDisplayed = false;

  constructor(private patientService: PatientService) {}

  get patient(): Patient {
    return this._patient;
  }

  @Input()
  set patient(patient: Patient) {
    this._patient = patient;
    this.loadEncounters();
  }

  private loadEncounters(): void {
    this.loading = true;

    this.patientService.getEncounters(this.patient.id).subscribe(
      result => {
        this.loading = false;
        if (result.meta.eligibilityBasedOnLocation) {
          this.showIneligibleEncounters = true;

          this.eligibleEncounters =
            result.encounters.filter(encounter => encounter.locationEligibleDuringServiceDate);

          this.ineligibleEncounters =
            result.encounters.filter(encounter => !encounter.locationEligibleDuringServiceDate);
        } else {
          this.showIneligibleEncounters = false;
          this.eligibleEncounters = result.encounters;
        }
        if (result.meta.paging.count > result.meta.paging.pageSize) {
          this.moreEncountersThanDisplayed = true;
        } else {
          this.moreEncountersThanDisplayed = false;
        }
      },
      (error: HttpErrorResponse) => {
        this.loading = false;
        console.error(error);
      }
    );
  }

  tabClick($event, tabName) {
    $event.preventDefault();
    this.currentTab = tabName;
  }
}
